import { Injectable } from '@angular/core';
import { NavigationItem } from '../interfaces/navigation-item.interface';

const ITEMS: NavigationItem[] = [
  {
    type: 'link',
    label: 'Statistics board',
    icon: 'mat:dashboard',
    route: 'dashboard/analytics',
    role: 'GetDash',
  },
  {
    type: 'link',
    label: 'Classification statistics',
    icon: 'mat:dashboard',
    route: 'classification-dashboard/analytics',
    role: 'BoShowClassDashboard',
  },
  {
    type: 'link',
    label: 'Inquiries',
    icon: 'mat:info',
    route: 'reception/inquiries-list',
    role: 'BoShowInquiries',
  },
  {
    type: 'link',
    label: 'Grouped cashier',
    icon: 'mat:payments',
    route: 'grouped-cashier-list',
    role: 'BoShowAllCashier',
  },
  {
    type: 'link',
    label: 'Archive',
    icon: 'mat:archive',
    route: 'archive',
    role: 'BoShowSuperAdmin',
  },
  {
    type: 'link',
    label: 'Add engineer',
    icon: 'mat:add',
    route: '/admin-management/engineer/add-engineer',
    role: 'BoShowCandidateAdd',
  },
  {
    type: 'dropdown',
    label: 'Users',
    icon: 'mat:group',
    role: 'BoShowUsers',
    children: [
      {
        type: 'link',
        label: 'Users list',
        icon: '',
        route: '/admin-management/users/list',
        role: 'BoShowUsersView',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'User Logout',
        icon: '',
        route: '/admin-management/users/actions/user-logout-list',
        role: 'BoShowLogoutUser',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Transfer person data',
        icon: '',
        route: '/admin-management/users/actions/person-data-transfer-list',
        role: 'BoShowUsersTransfer',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Individual persons',
        icon: '',
        route:
          '/admin-management/users/actions/persons-with-no-company-account',
        role: 'BoShowUsersWithNoCompanyAccount',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Register user',
        icon: '',
        route: '/admin-management/users/actions/create-user',
        role: 'BoShowUsersCreateAccount',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Reception',
    icon: 'mat:contacts',
    role: 'BoShowReception',
    children: [
      {
        type: 'link',
        label: 'Cashier',
        icon: '',
        route: '/reception/reception-cashier-list',
        role: 'BoShowReceptionCashier',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Data review',
        icon: '',
        route: '/reception/data-review-list',
        role: 'GetAllPersonDatapage',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Certificate verification',
        icon: '',
        route: '/reception/verify-certificate-list',
        role: 'BoShowReceptionInvestCompany',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Receipts',
        route: '/reception/reception-receipts-list',
        role: 'BoShowReceptionReceipts',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Transfer from abroad',
        route: '/review/person-data-with-aboard-list',
        role: 'BoShowReceptionTransFromOutside',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Accounting',
    icon: 'mat:account_balance',
    role: 'BoShowAccounting',
    children: [
      {
        type: 'link',
        label: 'Service Request',
        icon: '',
        route: '/accounting/service-request-list',
        role: 'BoShowAccountingRequests',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Cashier',
        icon: '',
        route: '/accounting/cashier-list',
        role: 'BoShowAccountingCashier',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Receipts',
        route: '/accounting/receipts-list',
        role: 'BoShowAccountingReceipts',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Request services',
        route: '/accounting/request-service-payment',
        role: 'BoShowAccountingApplayRequests',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Review',
    icon: 'mat:rate_review',
    role: 'BoShowReview',
    children: [
      {
        type: 'link',
        label: 'Final audition',
        icon: '',
        route: '/review/final-audition-list',
        role: 'BoShowReviewFinalAudition',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Final review',
        icon: '',
        route: '/review/final-review-list',
        role: 'BoShowReviewFinalAudition',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Completing documents',
        icon: '',
        route: '/review/completing-documents-list',
        role: 'BoShowReviewCompleatDocument',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Accepted files',
        icon: '',
        route: '/review/accepted-files-list',
        role: 'BoShowReviewAcceptedFiles',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Rejected files',
        icon: '',
        route: '/review/rejected-files-list',
        role: 'BoShowReviewRejectedFiles',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Blocked files',
        icon: '',
        route: '/review/blocked-files-list',
        role: 'BoShowReviewBlockedFiles',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Files not complete',
        icon: '',
        route: '/review/person-data-not-complete-list',
        role: 'BoShowReviewFilesNotCompleate',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Classification',
    icon: 'mat:rate_review',
    role: 'BoShowClassification',
    children: [
      {
        type: 'link',
        label: 'Classification requests',
        icon: '',
        route: '/classification/requests-list',
        role: 'BoShowClassifList',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Review classification data',
        icon: '',
        route: '/classification/review-data-list',
        role: 'BoShowClassifDataReview',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Panels',
        icon: '',
        route: '/classification/panels',
        role: 'BoShowClassifPanels',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Add examiner to panel',
        icon: '',
        route: '/classification/add-examiner-list',
        role: 'BoShowClassifAddExmnrTPanel',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Remove examiner from panel',
        icon: '',
        route: '/classification/remove-examiner-list',
        role: 'BoShowClassifRemoveExmnrFPanel',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Add classification results',
        icon: '',
        route: 'classification/evaluation-list',
        role: 'BoShowClassifExamResults',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Result accreditation',
        icon: '',
        route: '/classification/accredit-results-list',
        role: 'BoShowClassifExamApproval',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Classification certificates',
        icon: '',
        route: '/classification/classification-certificates-list',
        role: 'BoShowIssuedClassiCertificates',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Classification cards',
        icon: '',
        route: '/classification/classification-cards-list',
        role: 'BoShowIssuedClassiCards',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Classification stamps',
        icon: '',
        route: '/classification/classification-stamps-list',
        role: 'BoShowIssuedClassiStamp',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Old persons classification',
        icon: '',
        route: '/classification/old-persons-classification-list',
        role: 'BoShowOldClassification',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Exams list',
        icon: '',
        route: '/classification/exams',
        role: 'BoShowClassifExams',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Exam topics',
        icon: '',
        route: '/classification/exam-topics',
        role: 'BoShowClassifExamTopics',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Faculty members',
        icon: '',
        route: '/classification/faculty-members',
        role: 'BoShowClassifFacultyMShip',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Classification type',
        icon: '',
        route: '/classification/classification-type',
        role: 'BoShowClassifType',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Request services',
        route: '/classification/classification-request-service-payment',
        role: 'BoShowClassifApplayRequests',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      // {
      //   type: 'link',
      //   label: 'Re-Evaluate',
      //   icon: '',
      //   route: '/classification/re-evaluate-list',
      //   role: 'BoShowEvaluationReEvaluate',
      //   badge: {
      //     value: '',
      //     bgClass: '',
      //     icon: 'mat:add',
      //     textClass: 'text-deep-purple-contrast',
      //   }
      // },
    ],
  },
  // {
  //   type: 'link',
  //   label: 'Mail inbox',
  //   icon: 'mat:mail',
  //   route: 'mail-inbox',
  //   role: 'GetAllMail',
  // },
  {
    type: 'dropdown',
    label: 'Evaluation',
    icon: 'mat:group',
    role: 'BoShowEvaluation',
    children: [
      {
        type: 'link',
        label: 'Panels',
        icon: '',
        route: '/evaluation/panels',
        role: 'BoShowEvaluationPanels',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Add examiner to panel',
        icon: '',
        route: '/evaluation/add-examiner-list',
        role: 'BoShowEvaluationAddExaminerToPanel',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Remove examiner from panel',
        icon: '',
        route: '/evaluation/remove-examiner-list',
        role: 'BoShowRemoveExaminier',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Add Evaluation results',
        icon: '',
        route: '/evaluation/list',
        role: 'BoShowEvaluationAddResults',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Result accreditation',
        icon: '',
        route: '/evaluation/accredit-results-list',
        role: 'BoShowEvaluationResultAccreditation',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Faculty members',
        icon: '',
        route: '/evaluation/faculty-members',
        role: 'BoShowEvaluationFacultyMembers',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Re-Evaluate',
        icon: '',
        route: '/evaluation/re-evaluate-list',
        role: 'BoShowEvaluationReEvaluate',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'link',
    label: 'Issued certificates',
    icon: 'mat:wallpaper',
    route: 'issued-certificate-list',
    role: 'BoShowIssuedCertificates',
  },
  {
    type: 'link',
    label: 'Membership cards',
    icon: 'mat:card_membership',
    route: 'membership/membership-cards-list',
    role: 'BoShowMembershipCards',
  },
  {
    type: 'dropdown',
    label: 'Companies',
    icon: 'mat:location_city',
    role: 'BoShowCompanies',
    children: [
      {
        type: 'link',
        label: 'Companies list',
        icon: '',
        route: '/management/companies',
        role: 'BoShowCompaniesList',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Create new company profile',
        icon: '',
        route: '/management/company-register',
        role: 'BoShowCompaniesCreateAccount',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Company rating',
        icon: '',
        route: '/management/company-rating',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Ministries',
        icon: '',
        route: '/management/ministries',
        role: 'BoShowCompaniesMinistries',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Reports',
    icon: 'mat:insert_chart',
    role: 'BoShowReports',
    children: [
      {
        type: 'link',
        label: 'Classification success and failure',
        icon: '',
        route: '/reports/classification-success-and-failure-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evaluation success and failure',
        icon: '',
        route: '/reports/evaluation-success-and-failure-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Classification results',
        icon: '',
        route: '/reports/classification-results-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Classification panels',
        icon: '',
        route: '/reports/classification-panels-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Reception cashier ( Daily )',
        icon: '',
        route: '/reports/reception-cashier-daily-list',
        role: 'BoShowReportsReceptionDailyIncome',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Reception cashier ( Monthly )',
        icon: '',
        route: '/reports/reception-cashier-monthly-list',
        role: 'BoShowReportsReceptionMonthlyIncome',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Society cashier ( Daily )',
        icon: '',
        route: '/reports/society-cashier-daily-list',
        role: 'BoShowReportsAccountingDailyIncome',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Society cashier ( Monthly )',
        icon: '',
        route: '/reports/society-cashier-monthly-list',
        role: 'BoShowReportsAccountingMonthlyIncome',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Basic data',
        icon: '',
        route: '/reports/basic-data-list',
        role: 'PersonBasicDataDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Data statistics by country',
        icon: '',
        route: '/reports/data-statistics-by-country-list',
        role: 'PersonDataByNationStatistics',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Success and failed statistics',
        icon: '',
        route: '/reports/success-and-failed-statistics-list',
        role: 'GetSuccesCountStatistic',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evaluation statistics by nationality',
        icon: '',
        route: '/reports/evaluation-by-nationality-list',
        role: 'PersonDataEvaluationByNationStatistics',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evaluation statistics by job',
        icon: '',
        route: '/reports/evaluation-by-job-list',
        role: 'PersonDataEvaluationByJobStatistics',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evaluation details',
        icon: '',
        route: '/reports/evaluation-details-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Daily accounting',
        icon: '',
        route: '/reports/daily-accounting-list',
        role: 'DialyAccountingReprot',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Financial report by cashier',
        icon: '',
        route: '/reports/financial-report-by-cashier-list',
        role: 'BoShowReportsAccountingFinaRptCasher',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Financial report by reception cashier',
        icon: '',
        route: '/reports/financial-report-by-reception-cashier-list',
        role: 'BoShowReportsReceptionFinaRptCasher',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Financial report by service and cashier',
        icon: '',
        route: '/reports/financial-report-by-service-cashier-list',
        role: 'BoShowAccountingIncRptService',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Financial report by service and reception cashier',
        icon: '',
        route:
          '/reports/financial-report-by-service-and-reception-cashier-list',
        role: 'BoShowReceptionIncRptService',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Actions on files status',
        icon: '',
        route: '/reports/person-status-by-user-list',
        role: 'BoShowRptPersonStatus',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Actions on service request',
        icon: '',
        route: '/reports/service-request-by-user-list',
        role: 'BoShowRptServiceRequest',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Finance transactions',
        icon: '',
        route: '/reports/finance-transactions-list',
        role: 'BoShowRptPersonOperation',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'File transactions',
        icon: '',
        route: '/reports/file-transactions-list',
        role: 'BoShowRptPersonOperation',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },

      {
        type: 'link',
        label: 'Classification Report',
        icon: '',
        route: '/reports/classification-report-list',
        role: 'BoShowRptPersonOperation',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evalution Results',
        icon: '',
        route: '/reports/evalution-results-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evalution Panels',
        icon: '',
        route: '/reports/evalution-panels-list',
        role: 'GetPersonDataByEvaluationDetails',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Monthly classification interviews',
        icon: '',
        route: '/reports/monthly-classification-interviews-list',
        role: 'BoShowRptPersonOperation',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Other destinations',
    icon: 'mat:call_made',
    role: 'BoShowOutsideOrgnizations',
    children: [
      {
        type: 'link',
        label: 'Transfer to workforce',
        icon: '',
        route: '/other-destinations/transfer-to-workflow',
        role: 'BoShowToNOC',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Transfered from workforce',
        icon: '',
        route: '/other-destinations/workforce-transfered',
        role: 'BoShowInNOC',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Transfer to workforce - new',
        icon: '',
        route: '/other-destinations/transfer-to-workflow-new-list',
        role: 'BoShowToNOCNew',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Transferred from workforce - new',
        icon: '',
        route: '/other-destinations/workforce-transferred-new-list',
        role: 'BoShowInNOCNew',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'File management',
    icon: 'mat:file_copy',
    role: 'BoShowFileManagement',
    children: [
      {
        type: 'link',
        label: 'Change file status',
        icon: '',
        route: '/file-management/list',
        role: 'BoShowFileManagementChangeStatus',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Memberships management',
        icon: '',
        route: '/file-management/memberships-management-list',
        role: 'BoShowMembershipManagment',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },

      {
        type: 'link',
        label: 'Files statuses and notes',
        icon: '',
        route: '/files-statuses-notes',
        role: 'BoShowStatusAndCommentManage',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'System logs',
    icon: 'mat:view_list',
    role: 'BoShowSystemAudit',
    children: [
      {
        type: 'link',
        label: 'Audit category',
        icon: '',
        route: '/management/audit-category',
        role: 'BoShowAuditCategories',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'AuditData',
        icon: '',
        route: '/management/audit-data',
        role: 'BoShowAuditData',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'NOC audit',
        icon: '',
        route: '/management/audit-entries-list',
        role: 'BoShowAuditData',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Payment history',
        icon: '',
        route: '/management/payment-history',
        role: 'BoShowAuditOnlinePayment',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Notifications',
    icon: 'mat:notifications_active',
    role: 'BoShowNotification',
    children: [
      {
        type: 'link',
        label: 'Notifications',
        icon: '',
        route: '/management/notification-data',
        role: 'BoShowNotificationList',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Notification category',
        icon: '',
        route: '/management/notification-category',
        role: 'BoShowNotificationCategory',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Messages settings',
        icon: '',
        route: '/management/messages-settings',
        role: 'BoShowMessagesSetting',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Contact Us',
        icon: '',
        route: '/management/contact-us',
        role: 'BoShowContactUs',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Outgoing Messages',
        icon: '',
        route: '/management/outgoing-messages',
        role: 'BoShowRecievedMessages',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Chats',
    icon: 'mat:chat',
    role: 'BoShowSuperAdmin',
    children: [
      {
        type: 'link',
        label: 'Chat Services',
        icon: '',
        route: '/chat/chat-services',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Chat messages',
        icon: '',
        route: '/chat/chat-messages',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Chat employees',
        icon: '',
        route: '/chat/chat-employees',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Appointment booking settings',
    icon: 'mat:access_alarms',
    role: 'BoShowAppointmentSetting',
    children: [
      {
        type: 'link',
        label: 'Shifts',
        icon: '',
        route: '/management/shift',
        role: 'BoShowAppointmentSettingShifts',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Holidays',
        icon: '',
        route: '/management/holidays',
        role: 'BoShowAppointmentHolidays',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Booking types',
        icon: '',
        route: '/management/appointment-type',
        role: 'BoShowAppointmentTypeView',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Booking info',
        icon: '',
        route: '/management/appointment-info',
        role: 'BoShowAppointmentInfoView',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Booking settings',
        icon: '',
        route: '/management/appointment-setting',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Evaluation settings',
    icon: 'mat:settings',
    role: 'BoShowEvaluationSettings',
    children: [
      {
        type: 'link',
        label: 'Evaluation type',
        icon: '',
        route: '/management/evaluation-type',
        role: 'BoShowEvaluationTypeView',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evaluation status',
        icon: '',
        route: '/management/evaluation-status',
        role: 'BoShowEvaluationStatusView',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Evaluation settings',
        icon: '',
        route: '/management/evaluation-settings',
        role: 'BoShowEvaluationSettingView',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Data registration settings',
    icon: 'mat:engineering',
    role: 'BoShowDataRegistrationSetting',
    children: [
      {
        type: 'link',
        label: 'Nationalities',
        icon: '',
        route: '/management/nationalities',
        role: 'BoShowDataRegistrationSettingNationalities',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Countries',
        icon: '',
        route: '/management/countries',
        role: 'BoShowDataRegistrationSettingCountries',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Gender',
        icon: '',
        route: '/management/genders',
        role: 'BoShowDataRegistrationSettingGender',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Residency type',
        icon: '',
        route: '/management/residency-types',
        role: 'BoShowRegistrationSettingResidencyType',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Jobs',
        icon: '',
        route: '/management/jobs',
        role: 'BoShowDataRegistrationSettingJobs',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Document types',
        icon: '',
        route: '/management/document-types',
        role: 'BoShowDataRegistrationSettingDocumentTypes',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Documents Reasons',
        icon: '',
        route: '/management/requied-documents-reasons',
        role: 'BoShowDocumentRequireReason',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Districts',
        icon: '',
        route: '/management/districts',
        role: 'BoShowDataRegistrationSettingDistricts',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Jobs recommended',
        icon: '',
        route: '/management/jobs-recommended',
        role: 'BoShowKSEJob',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Education settings',
    icon: 'mat:school',
    role: 'BoShowEducationSetting',
    children: [
      {
        type: 'link',
        label: 'Education rating',
        icon: '',
        route: '/management/education-rating',
        role: 'BoShowEducationRating',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Education level',
        icon: '',
        route: '/management/certificate-type',
        role: 'BoShowEducationType',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Universities',
        icon: '',
        route: '/management/university',
        role: 'BoShowSchoolAndUniversity',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Colleges',
        icon: '',
        route: '/management/college',
        role: 'BoShowSectionsAndCollage',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Professions',
        icon: '',
        route: '/management/profession',
        role: 'BoShowProfessions',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Sub professions',
        icon: '',
        route: '/management/sub-profession',
        role: 'BoShowSubProfessions',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Field',
        icon: '',
        route: '/management/classfield',
        role: 'BoShowSubProfessions',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Approved years',
        icon: '',
        route: '/management/approved-years',
        role: 'BoShowAcceptedYears',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Investigation company settings',
    icon: 'mat:verified_user',
    role: 'BoShowInvestCompany',
    children: [
      {
        type: 'link',
        label: 'Verification certificates',
        icon: '',
        route: '/management/verification-certificate',
        role: 'BoShowInvestCompanyCerts',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Verification statuses',
        icon: '',
        route: '/management/certver-status',
        role: 'BoShowInvestCompanyStatus',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Certificates status',
        icon: '',
        route: '/management/certvertype-status',
        role: 'BoShowInvestCompanyCertStatus',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'Requests settings',
    icon: 'mat:settings',
    role: 'BoShowRequestSetting',
    children: [
      {
        type: 'link',
        label: 'Services',
        icon: '',
        route: '/management/services',
        role: 'BoShowRequestSettingServices',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Service settings',
        icon: '',
        route: '/management/service-setting',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Payment methods',
        icon: '',
        route: '/management/payment-method',
        role: 'BoShowRequestSettingPaymentMethods',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Discounts',
        icon: '',
        route: '/management/discount',
        role: 'BoShowRequestSettingDiscounts',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Request status',
        icon: '',
        route: '/management/request-status',
        role: 'BoShowRequestSettingRequestStatus',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Organizations',
        icon: '',
        route: '/management/organizations',
        role: 'BoShowRequestSettingOrganizations',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },

  {
    type: 'dropdown',
    label: 'System settings',
    icon: 'mat:settings_applications',
    role: 'BoShowSystemSettings',
    children: [
      {
        type: 'link',
        label: 'Application settings',
        icon: '',
        route: '/management/application-settings',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Page settings',
        icon: '',
        route: '/management/page-settings',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Application pages',
        icon: '',
        route: '/management/application-pages-settings',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Roles groups',
        icon: '',
        route: '/management/roles-groups',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'File status',
        icon: '',
        route: '/management/file-status',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Website pages',
        icon: '',
        route: '/management/fo-pages',
        role: 'BoShowFoPages',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Approve settings',
        icon: '',
        route: '/management/approve-settings',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
      {
        type: 'link',
        label: 'Membership types',
        icon: '',
        route: '/management/membership-types',
        role: 'BoShowSuperAdmin',
        badge: {
          value: '',
          bgClass: '',
          icon: 'mat:add',
          textClass: 'text-deep-purple-contrast',
        },
      },
    ],
  },
];

@Injectable()
export class SideNavItems {
  getAll(): NavigationItem[] {
    return ITEMS;
  }
}
