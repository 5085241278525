import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Location } from '@angular/common';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  delay,
  tap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { secureStorage } from '../shared/functions/secure-storage';
import { LoaderService } from './loader.service';
import { AuthService } from './auth.service';
import {
  encrypt,
  GetLanguage,
  HandleResponseError,
} from '../shared/functions/shared-functions';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { dateTimeFormat } from '../shared/variables/variables';
import { CoreService } from './core.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private token = secureStorage.getItem('token');

  constructor(
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private coreService: CoreService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes('/api/ApplicationSetting/ServerTime')) {
      this.loaderService.show();
    }
    req = this.addAuthenticationToken(req);
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && this.loaderService.showLoader) {
          this.loaderService.hide();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        let activeToast;
        if (error && (error.status === 401 || error.status === 403)) {
          this.translateService
            .get('not-authorized')
            .pipe(take(1))
            .subscribe((phrase) => {
              this.toastr?.clear();
              if (!activeToast) {
                activeToast = this.toastr.show(phrase);
              }
            });
          setTimeout(() => {
            this.authService.logOut();
          }, 2000);
        } else if (error && (error.status === 500 || error.status === 0)) {
          this.toastr.error(
            this.translateService.instant(
              'An error has occurred, please try again later',
            ),
          );
        } else if (
          error &&
          error.status === 400 &&
          !req.url.includes('StartPayment')
        ) {
          this.toastr.error(
            this.translateService.instant(
              HandleResponseError(this.coreService.handleError(error, req.url)),
            ),
          );
          return throwError(error);
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private tokenExpired() {
    this.toastr.error(
      this.translateService.instant('Logged out'),
      this.translateService.instant('Session ended'),
    );
    this.authService.logOut();
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    this.token = secureStorage.getItem('token');
    const tokenExpiration = secureStorage.getItem('expire');
    if (tokenExpiration && moment() > moment(tokenExpiration)) {
      this.tokenExpired();
    }
    if (!this.token) {
      return request.clone({
        //headers: request.headers.delete('Content-Type')
        headers: request.headers.set('Content-Type', 'application/json'),
      });
      // return request;
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    // If you are calling an outside domain then do not add the token.
    if (
      request.url.includes('Login') ||
      (request.url.includes('Register') &&
        !request.url.includes('RegisterBackOffice')) ||
      request.url.includes('Password') ||
      request.url.includes('assets') ||
      request.url.includes('restcountries')
    ) {
      return request.clone({
        headers: request.headers,
      });
    }
    if (request.url.includes('filescanner')) {
      return request.clone({
        headers: request.headers.delete('Content-Type'),
      });
    }
    if (
      request.url.includes('Document/UplaodDocs') ||
      request.url.includes('upload-document') ||
      request.url.includes('UserUploadDocs')
    ) {
      return request.clone({
        headers: request.headers
          .delete('Content-Type')
          .set(this.AUTH_HEADER, `Bearer ${this.token}`),
      });
    }
    if (
      request.url.includes('Document/Donloadfile') ||
      request.url.includes('Document/CreatePdf')
    ) {
      return request.clone({
        headers: request.headers
          .set('Accept', 'text/html, application/xhtml+xml, */*')
          .set('Content-Type', 'application/x-www-form-urlencoded'),
        // headers: new HttpHeaders({
        //   'Accept': 'text/html, application/xhtml+xml, */*',
        //   'Content-Type': 'application/x-www-form-urlencoded'
        // }),
        responseType: 'blob' as 'json',
      });
    }
    return request.clone({
      // headers: request.headers.set(this.AUTH_HEADER, this.token)
      setHeaders: {
        Authorization: `Bearer ${this.token}`,
        language: GetLanguage(),
      },
    });
  }
}
