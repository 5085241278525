import {
  ChangeDetectorRef,
  Component,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../../../services/core.service';
import { ToastrService } from 'ngx-toastr';
import {
  FilterRes,
  GetCurrentUser,
  getCurrentUserRoles,
  GetLanguage,
  HandleResponseError,
} from '../../functions/shared-functions';
import { secureStorage } from '../../functions/secure-storage';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PageTitleService } from '../../../core/components/page-title/page-title.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BreadcrumbsService } from '@exalif/ngx-breadcrumbs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'service-collection',
  templateUrl: './service-collection.component.html',
  styleUrls: ['./service-collection.component.scss'],
})
export class ServiceCollectionComponent implements OnInit, AfterViewInit {
  selectedPayment: any;
  collectionData: any;
  discountTypes: any[] = [];
  paymentMethods: any[] = [];
  filteredPayment: any[] = [];
  selectedDiscount: any;
  totalPrice: any;
  mainPrice: number;
  name: string;
  priceAfterDiscount: any = null;
  discountTypeId: any;
  paymentMethodId: any;
  discountValue: any;
  noDiscountId: any;
  form: FormGroup;
  roles = getCurrentUserRoles();
  pageCode: string;
  loadBtn = false;
  showNumOfYears: boolean;
  comingNumberOfYear;
  isGroupedCashier = false;
  showMemberShipInfo = false;
  collectionDataId;
  casheir_type;
  min = 1;

  constructor(
    private coreService: CoreService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private pageTitleService: PageTitleService,
    private cdr: ChangeDetectorRef,
    private breadcrumbsService: BreadcrumbsService,
  ) {}

  ngOnInit(): void {
    this.isGroupedCashier =
      this.activatedRoute.snapshot.queryParams.isGroupedCashier;
    this.casheir_type = this.activatedRoute.snapshot.queryParams.casheir_type;
    this.collectionDataId = secureStorage.decryptItem(
      this.activatedRoute.snapshot.queryParams?.id?.replaceAll(' ', '+'),
    );
    this.form = new FormGroup({
      payment: new FormControl(null, [Validators.required]),
      refNum: new FormControl(null, []),
      collected_service: new FormControl(null, []),
      organization: new FormControl(null, []),
      discount: new FormControl(null, [Validators.required]),
      numOfYears: new FormControl(null, [
        Validators.nullValidator,
        Validators.min(this.min),
      ]),
    });
    this.form.valueChanges.subscribe((value) => {});
    this.applyDiscountRole();
    this.getCollectionData();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  changeDiscount() {
    this.selectedDiscount = this.form.get('discount').value;
    this.checkDiscount(this.selectedDiscount);
    this.form.updateValueAndValidity();
  }

  applyDiscountRole() {
    if (!this.roles?.includes('BoShowDiscount')) {
      this.form.controls['discount'].disable();
    }
  }

  changePayment() {
    this.selectedPayment = this.form.get('payment').value;
    // console.log('Need reason', this.selectedPayment?.isNeedReferenceNumber);
    if (this.selectedPayment?.isNeedReferenceNumber) {
      this.form.controls['refNum'].setValidators([Validators.required]);
    } else {
      this.form.controls['refNum'].setValue(null);
      this.form.controls['refNum'].setErrors(null);
    }
    this.form.updateValueAndValidity();
  }

  getCollectionData() {
    if (secureStorage.getItem(`collectionData-${this.collectionDataId}`)) {
      this.collectionData = secureStorage.getItem(
        `collectionData-${this.collectionDataId}`,
      );
      this.pageCode =
        this.collectionData.cashierType === 'cashier' ? 'CaPosX2' : 'ReCaPosX1';
      if (this.collectionData?.personMembership?.expiryDate) {
        this.collectionData.personMembership.expiryDate = moment(
          this.collectionData.personMembership?.expiryDate,
        )
          ?.locale('en')
          .format('yyyy-MM-DD');
      }
      if (this.collectionData.putAction) {
        this.pageTitleService.setTitle('Edit');
      }
      this.setBreadCrumbs();
      this.showMemberShipInfo =
        this.collectionData?.service?.actionCode === 'RenewAnnualmembership';
      if (
        this.collectionData?.service?.actionCode === 'RenewAnnualmembership' &&
        this.collectionData?.serviceRequest?.organization
      ) {
        this.form.controls['organization'].setValue(
          GetLanguage() === 'ar'
            ? this.collectionData?.serviceRequest?.organization?.nameAr
            : this.collectionData?.serviceRequest?.organization?.nameEn,
        );
      }
      if (this.collectionData?.serviceRequest?.service?.nameAr) {
        this.form.controls['collected_service'].setValue(
          GetLanguage() === 'ar'
            ? this.collectionData?.serviceRequest?.service?.nameAr
            : this.collectionData?.serviceRequest?.service?.nameEn,
        );
      } else {
        this.form.controls['collected_service'].setValue(
          this.collectionData?.serviceName,
        );
      }
      this.checkRenewAnnualmembership();
      if (!this.collectionData.putAction) {
        this.setExpiryDateAfterYears(true);
      } else {
        if (this.collectionData.personMembership) {
          this.collectionData.personMembership.expiryDateAfterYears =
            this.collectionData?.personMembership?.expiryDate;
        }
      }
      this.handleCollectionData();
      this.getDiscount();
      this.getPayment();
    }
    this.form.markAllAsTouched();
  }

  setBreadCrumbs() {
    this.breadcrumbsService.crumbs$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (this.collectionData.putAction) {
          this.translate.get('Edit').subscribe((word) => {
            res.push({ text: word, path: '/' });
          });
        } else {
          this.translate.get('Collect').subscribe((word) => {
            res.push({ text: word, path: '/' });
          });
        }
      });
  }

  setRenewalMin() {
    if (this.collectionData?.service?.actionCode === 'RenewAnnualmembership') {
      const expiry_date = moment(
        this.collectionData.personMembership?.expiryDate,
      ).year();
      const current_date = moment().year();
      const diff = current_date - expiry_date;
      if (diff > 0) {
        this.min = diff;
      } else {
        this.min = 1;
      }
    } else {
      this.min = 1;
    }
  }

  setExpiryDateAfterYears(firstLoad?) {
    const expiry_year = moment(
      this.collectionData?.personMembership?.expiryDate,
    ).year();
    let diff;
    let after_adding_years;
    if (+this.comingNumberOfYear > +this.form?.value?.numOfYears) {
      diff = +this.comingNumberOfYear - +this.form?.value?.numOfYears || 0;
      after_adding_years = expiry_year - (+diff ?? 0);
    } else {
      diff = +this.form?.value?.numOfYears - +this.comingNumberOfYear || 0;
      after_adding_years = expiry_year + (+diff ?? 0);
    }
    if (!this.collectionData.putAction) {
      if (+this.comingNumberOfYear > +this.form?.value?.numOfYears) {
        after_adding_years =
          expiry_year + (+this.comingNumberOfYear ?? 0) - diff;
      } else {
        after_adding_years =
          expiry_year + (+this.comingNumberOfYear ?? 0) + diff;
      }

      // after loading the page before editing number of years
      if (firstLoad) {
        after_adding_years = expiry_year + (+this.comingNumberOfYear ?? 0);
      }
    }
    if (this.collectionData?.personMembership?.expiryDateAfterYears) {
      this.collectionData.personMembership.expiryDateAfterYears = moment(
        this.collectionData?.personMembership?.expiryDateAfterYears,
      )
        .set('year', after_adding_years)
        ?.locale('en')
        ?.format('yyyy-MM-DD');
    } else if (this.collectionData?.personMembership?.expiryDate) {
      this.collectionData.personMembership.expiryDateAfterYears = moment(
        this.collectionData?.personMembership?.expiryDate,
      )
        .set('year', after_adding_years)
        ?.locale('en')
        ?.format('yyyy-MM-DD');
    }
  }

  checkRenewAnnualmembership() {
    this.showNumOfYears =
      this.collectionData?.service?.actionCode === 'RenewAnnualmembership';
    if (this.showNumOfYears) {
      if (!this.collectionData.putAction) {
        this.form.controls['numOfYears'].setValue(
          this.collectionData?.serviceRequest?.numOfYears,
        );
      } else {
        this.form.controls['numOfYears'].setValue(
          this.collectionData.numOfYears,
        );
      }
      this.comingNumberOfYear = this.form.value?.numOfYears;
      this.form.controls['numOfYears'].setValidators([Validators.required]);
    }
    this.setRenewalMin();
  }

  handleCollectionData() {
    this.totalPrice = this.collectionData.totalPrice;
    this.mainPrice = this.collectionData?.service?.price;

    //this.collectionData.nameAr = this.collectionData.firstNameAr + ' ' + (this.collectionData.secondNameAr !== '-' ? this.collectionData.secondNameAr : '') + ' ' + (this.collectionData.thirdNameAr  !== '-'  ? this.collectionData.thirdNameAr : '') + ' ' + this.collectionData.familyNameAr;
    //this.collectionData.nameEn = this.collectionData.firstNameEN + ' ' + (this.collectionData.secondNameEN  !== '-' ? this.collectionData.secondNameEN : '') + ' ' + (this.collectionData.thirdNameEN !== '-' ? this.collectionData.thirdNameEN : '') + ' ' + this.collectionData.familyNameEN;
    this.name =
      GetLanguage() === 'ar'
        ? this.collectionData.nameAr
        : this.collectionData.nameEn;

    // HANDLE DIFFERENT DATA FROM CASHIER & RECEIPTS SCREENS
    if (!this.collectionData.putAction) {
      this.discountTypeId = this.collectionData.serviceRequest.discountTypeId;
      this.paymentMethodId = this.collectionData.serviceRequest.paymentMethodId;
      this.collectionData.serviceName =
        GetLanguage() === 'ar'
          ? this.collectionData.serviceRequest.service.nameAr
          : this.collectionData.serviceRequest.service.nameEn;
    } else {
      this.discountTypeId = this.collectionData.discountTypeId;
      this.paymentMethodId = this.collectionData.paymentMethodId;
      if (!this.collectionData.serviceName) {
        this.collectionData.serviceName =
          GetLanguage() === 'ar'
            ? this.collectionData.service.nameAr
            : this.collectionData.service.nameEn;
      }
      if (this.collectionData?.referenceNumber) {
        this.form.controls['refNum'].setValue(
          this.collectionData?.referenceNumber,
        );
      }
    }
    this.collectionData.name =
      GetLanguage() === 'ar'
        ? this.collectionData.arabicName
        : this.collectionData.englishName;
    // console.log('Data:', this.collectionData);
  }

  getDiscount() {
    this.coreService.getRequest('DiscountType').subscribe(
      (res) => {
        // console.log('res des', res);
        const filteredRes = FilterRes(res);
        this.noDiscountId = filteredRes.find((x) => x.value === 0)?.id;
        // SET DISCOUNT TO NO DISCOUNT IF USER DOESN'T HAVE DISCOUNT
        const defaultDiscount =
          this.discountTypeId !== null
            ? this.discountTypeId
            : this.noDiscountId;
        this.selectedDiscount = filteredRes.find(
          (x) => x.id === defaultDiscount,
        );
        this.checkDiscount(this.selectedDiscount);
        this.form.controls['discount'].setValue(this.selectedDiscount);
        // Get Arabic & English discount depending on lang
        filteredRes.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.discountTypes = filteredRes;
      },
      (err) => {
        // console.log('err', err)
      },
    );
  }

  checkDiscount(discData) {
    this.selectedDiscount = discData;
    if (discData?.isPercentage) {
      this.discountValue = (this.totalPrice * discData?.value) / 100;
      this.priceAfterDiscount = this.totalPrice - this.discountValue;
    } else {
      this.discountValue = discData?.value ? discData?.value : 0;
      this.priceAfterDiscount = this.totalPrice - this.discountValue;
    }
  }

  changeNumOfYears(numOfYears) {
    const value = Number(numOfYears.target.value);
    if (value && value < 1) {
      return;
    }
    if (value) {
      this.totalPrice = this.mainPrice * value;
      this.checkDiscount(this.selectedDiscount);
    } else {
      this.totalPrice = this.collectionData.totalPrice;
      this.checkDiscount(this.selectedDiscount);
    }
    this.setExpiryDateAfterYears();
  }

  getPayment() {
    this.coreService.getRequest('PaymentMethod').subscribe((res) => {
      this.filteredPayment = res.filter((el) => {
        return (
          el.isActive === true && el.isDeleted === false && el.isOline === false
        );
      });
      this.selectedPayment = res.find((x) => x.id === this.paymentMethodId);
      this.form.controls['payment'].setValue(this.selectedPayment);
      res?.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
      );
      this.paymentMethods = res;
    });
  }

  collect() {
    this.loadBtn = true;
    const body = {
      totalPrice: this.priceAfterDiscount,
      discount: this.discountValue,
      price: this.totalPrice,
      paymentMethodId: this.form.value.payment?.id,
      userId: GetCurrentUser()?.id,
      discountTypeId: this.form.getRawValue().discount?.id,
      serviceId: this.collectionData.serviceId,
      personDataId: !this.collectionData.putAction
        ? this.collectionData?.id
        : this.collectionData?.personDataId,
      serviceRequestId: this.collectionData.serviceRequestId,
      casherType: this.pageCode,
      auditPageCode: this.isGroupedCashier ? 'ReCaPosXAll' : this.pageCode,
    };
    if (this.form.value.refNum) {
      body['referenceNumber'] = this.form.value.refNum;
    }
    if (this.isGroupedCashier) {
      body['description'] = 'this collection is coming from grouped cashier!';
    }
    if (this.showNumOfYears) {
      body['numOfYears'] = this.form.value.numOfYears;
    }
    // CHECK FOR ONLINE PAYMENT
    if (this.selectedPayment.isOline) {
      // this.proceedPayment();
      const personDataId = secureStorage.encryptItem(this.collectionData.id);
      const serviceReqId = secureStorage.encryptItem(
        this.collectionData.serviceRequestId,
      );
      this.collectionData.updatedData = body;
      secureStorage.setItem(
        `collectionData-${this.collectionData.id}-${this.collectionData.serviceRequestId}-${this.selectedPayment?.id}`,
        this.collectionData,
      );
      this.router.navigate(['payment/confirm-online-payment', personDataId], {
        queryParams: {
          serviceReqId: serviceReqId,
          paymentMethodId: this.selectedPayment?.id,
        },
      });
    } else {
      if (this.collectionData.from_service_request) {
        this.updateServiceRequest(body);
      } else {
        if (this.collectionData.putAction) {
          body['receiptDate'] = this.collectionData.receiptDate;
          body['receiptNum'] = this.collectionData.receiptNum;
          this.putCashier(body);
        } else {
          this.postCashier(body);
        }
      }
    }
  }

  updateServiceRequest(body) {
    body['personDataId'] = this.collectionData?.id;
    body['serviceId'] = this.collectionData.service.id;
    body['requestStatusId'] = this.collectionData.requestStatusId;
    this.coreService
      .putRequest(
        `ServiceRequest/UpdateServiceRequest/${this.collectionData?.serviceRequestId}`,
        body,
      )
      .subscribe(
        (res) => {
          this.translate.get('Updated successfully').subscribe((word) => {
            this.toastr.success(word);
          });
        },
        (err) => {
          this.toastr.error(HandleResponseError(err));
          this.loadBtn = false;
          // console.log('err', err)
        },
      );
  }

  postCashier(body) {
    this.coreService.postRequest('Casher', body).subscribe(
      (res) => {
        this.translate.get('Paid successfully').subscribe((word) => {
          this.toastr.success(word);
          secureStorage.setItem('collectionData', this.collectionData);
          secureStorage.setItem('discountData', res);
          this.router.navigate(['/print-screen'], {
            queryParams: {
              id: secureStorage.encryptItem(res[0]?.id),
              casheir_type: this.casheir_type,
            },
          });
        });
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
        this.loadBtn = false;
        // console.log('err', err)
      },
    );
  }

  putCashier(body) {
    this.coreService
      .putRequest(`Casher/${this.collectionData?.id}`, body)
      .subscribe(
        (res) => {
          this.translate.get('Updated successfully').subscribe((word) => {
            this.toastr.success(word);
            secureStorage.setItem('collectionData', this.collectionData);
            secureStorage.setItem('discountData', res);
            this.router.navigate(['/print-screen'], {
              queryParams: {
                id: secureStorage.encryptItem(res[0]?.id),
                casheir_type: this.casheir_type,
              },
            });
          });
        },
        (err) => {
          this.toastr.error(HandleResponseError(err));
          this.loadBtn = false;
          // console.log('err', err)
        },
      );
  }

  proceedPayment() {
    this.httpClient
      .post(
        `${environment.apiUrl}Payment/StartPayment?AuditPage=FOStartPaymentBackoffice&IsBackOffice=true&Amount=${this.totalPrice}&serviecRequestId=${this.collectionData.serviceRequestId}${this.form?.value?.discount ? `&discountId=${this.form?.value?.discount?.id}` : ''}&cashiertype=${this.pageCode}&paymentMethodId=${this.form.value.payment?.id}&UserId=${GetCurrentUser()?.id}${this.form?.value?.numOfYears ? `&NumOfYears=${this.form?.value?.numOfYears}` : ''}`,
        {},
        { responseType: 'text' },
      )
      .subscribe(
        (res) => {
          res = environment.encryptedResponse
            ? this.coreService.decryptResponse(res)
            : res;
          // this.printCertOrCard()
          window.open(res, '_blank');
          //window.location.href = res;
        },
        (error) => {
          this.toastr.error(HandleResponseError(error));
        },
      );
  }
}
