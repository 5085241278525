<div
  class="overflow-auto table-content"
  (scroll)="scrollTable($event)"
  [ngClass]="{
    'table-content-with-tabs':
      url?.includes('complaints/list') || url.includes('content/list'),
  }"
>
  <div
    class="bg-app-bar px-6 border-b sticky table-header flex items-center ltr:left-0"
    id="table-bar"
    [ngClass]="{ 'p-0': isMobile }"
    *ngIf="has_nav"
  >
    <!--    <h2-->
    <!--      class="title d-flex my-0 ltr:pr-4 rtl:pl-4 ltr:border-r rtl:border-l hidden sm:block flex-none">-->
    <!--      <span *ngIf="selection.isEmpty()">{{pluralTableLabel | translate}}</span>-->
    <!--      <span *ngIf="selection.hasValue()">{{ selection.selected.length }}-->
    <!--        {{ (selection.selected.length > 1 ? pluralTableLabel : singleTableLabel) | translate}}<span-->
    <!--          *ngIf="selection.selected.length > 1"></span> {{'selected' | translate}}</span>-->
    <!--    </h2>-->
    <button
      *ngIf="hasAdd && checkRoles('Create')"
      (click)="create()"
      class="d-flex align-items-center justify-content-center bg-white add-btn"
      mat-mini-fab
      tooltip="{{ 'Add' | translate }} {{ singleTableLabel | translate }}"
      type="button"
    >
      <mat-icon svgIcon="mat:add" class="primary-text"></mat-icon>
    </button>
    <div
      *ngIf="selection.hasValue()"
      class="pr-4 border-r flex-none flex justify-content-center align-items-center"
    >
      <button
        (click)="delete(selection.selected)"
        *ngIf="hasDelete"
        mat-icon-button
        tooltip="{{ 'Delete selected' | translate }}"
        type="button"
        style="padding: 7px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
          />
        </svg>
      </button>
      <button
        (click)="logoutSelected(selection.selected)"
        *ngIf="listName === 'logoutUsers'"
        mat-icon-button
        tooltip="{{ 'Logout selected' | translate }}"
        type="button"
        style="padding: 7px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fit=""
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
        >
          <path
            d="M5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5zm16 7-4-4v3H9v2h8v3l4-4z"
          ></path>
        </svg>
      </button>
      <button
        (click)="exportExcel('select')"
        mat-icon-button
        class="d-flex justify-content-center align-items-center"
        tooltip="{{ 'Export selected' | translate }}"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 384 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
          />
        </svg>
      </button>
    </div>
    <button
      *ngIf="hasAddAllResultsBtn"
      (click)="addResultEvent()"
      class="d-flex align-items-center justify-content-center"
      mat-icon-button
      tooltip="{{ 'Save all results' | translate }}"
      type="button"
    >
      <!--      <mat-icon svgIcon="mat:save" class=""></mat-icon>-->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"
        />
      </svg>
    </button>
    <button
      tooltip="{{ 'Download' | translate }} {{ 'PDF' | translate }}"
      *ngIf="downloadReportPdf"
      (click)="downloadReportAsPdf('pdf')"
      class="d-flex justify-content-center rounded-full align-items-center"
      mat-icon-button
      type="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"
        />
      </svg>
    </button>
    <button
      tooltip="{{ 'Download' | translate }} {{ 'Excel' | translate }}"
      *ngIf="downloadReportExcel"
      (click)="downloadReportAsExcel('excel')"
      class="d-flex justify-content-center rounded-full align-items-center"
      mat-icon-button
      type="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 384 512"
      >
        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          d="M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z"
        />
      </svg>
    </button>

    <button
      *ngIf="!downloadReportExcel"
      (click)="exportExcel('all')"
      mat-icon-button
      tooltip="{{ 'Export all' | translate }}"
      class="d-flex justify-content-center align-items-center"
      type="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 384 512"
      >
        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          d="M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z"
        />
      </svg>
    </button>
    <div
      *ngIf="selection.hasValue() && hasTransferAllToWorkforceBtn"
      class="mr-4 pr-4 border-r flex-none flex justify-content-center align-items-center"
    >
      <button
        mat-raised-button=""
        (click)="transferAllToWorkforceEvent(selection.selected)"
        type="button"
        class="mat-focus-indicator ng-tns-c353-139 mat-raised-button mat-button-base mat-primary"
      >
        <span class="mat-button-wrapper"
          ><mat-icon class="small-icon" svgIcon="mat:call_made"></mat-icon>
          {{ 'Transfer' | translate }}</span
        >
      </button>
    </div>
    <div
      *ngIf="selection.hasValue() && hasStopTransferAllToWorkforceBtn"
      class="mr-4 pr-4 border-r flex-none flex justify-content-center align-items-center"
    >
      <button
        mat-raised-button=""
        (click)="stopTransferAllToWorkforceEvent(selection.selected)"
        type="button"
        class="mat-focus-indicator ng-tns-c353-139 mat-raised-button mat-button-base mat-primary"
      >
        <span class="mat-button-wrapper">
          <mat-icon class="small-icon" svgIcon="mat:call_received"></mat-icon>
          {{ 'Cancel transfer' | translate }}</span
        >
      </button>
    </div>
    <div
      *ngIf="
        selection.hasValue() &&
        (listName === 'accCashier' || listName === 'receptionCashier')
      "
      class="mr-4 pr-4 border-r flex-none flex justify-content-center align-items-center"
    >
      <button
        mat-raised-button=""
        (click)="collectSelected(selection.selected)"
        type="button"
        class="mat-focus-indicator ng-tns-c353-139 mat-raised-button mat-button-base mat-primary"
      >
        <span class="mat-button-wrapper">
          <mat-icon class="small-icon" svgIcon="mat:shopping_cart"></mat-icon>
          {{ 'Collect selected' | translate }}</span
        >
      </button>
    </div>
    <span class="flex-1"></span>
    <button
      *ngIf="(inList$ | async) && pluralTableLabel === 'Roles groups'"
      (click)="registerSuperAdmin()"
      tooltip="{{ 'Admin initialize' | translate }}"
      class="ltr:ml-1 rtl:mr-1 rtl:ml-3 d-flex justify-content-center rounded-full align-items-center search-btn"
      type="button"
    >
      <span>{{ 'Admin initialize' | translate }}</span>
    </button>
    <button
      *ngIf="(inList$ | async) && hasFilter"
      (click)="toggleFilter()"
      tooltip="{{ 'Search' | translate }}"
      class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center rounded-full align-items-center search-btn"
      type="button"
    >
      <span *ngIf="!filterOpened">{{ 'Search' | translate }}</span>
      <span *ngIf="filterOpened">{{ 'Close search' | translate }}</span>
      <!--      <mat-icon-->
      <!--        *ngIf="!filterOpened"-->
      <!--        svgIcon="mat:filter_alt"-->
      <!--        tooltip="{{ 'Open' | translate }} {{ 'Filter' | translate }}"-->
      <!--      ></mat-icon>-->
      <!--      <mat-icon-->
      <!--        *ngIf="filterOpened"-->
      <!--        svgIcon="mat:filter_alt_off"-->
      <!--        tooltip="{{ 'Open' | translate }} {{ 'Filter' | translate }}"-->
      <!--      ></mat-icon>-->
    </button>
    <button
      *ngIf="hasGrid && list_type === 'grid'"
      (click)="toggleList('table')"
      class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center align-items-center"
      color="primary"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:table_rows"></mat-icon>
    </button>
    <button
      *ngIf="hasGrid && list_type === 'table'"
      (click)="toggleList('grid')"
      class="ltr:ml-1 rtl:mr-1 d-flex justify-content-center rounded-full align-items-center"
      color="primary"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:grid_on"></mat-icon>
    </button>
    <button
      *ngIf="list_type === 'table'"
      [matMenuTriggerFor]="columnFilterMenu"
      class="flex-none"
      mat-icon-button
      tooltip="{{ 'Filter Columns' | translate }}"
      type="button"
      style="padding: 7px"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
  </div>

  <div
    class="filter"
    id="filter"
    [ngClass]="{ 'filter-opened': filterOpened && (inList$ | async) }"
  >
    <app-filter
      (filter)="filter($event)"
      [listName]="listName"
      [valuesGoingToFilter]="valuesGoingToFilter"
      *ngIf="hasFilter && !expandedTable"
      [monthFilter]="monthFilter"
      (monthSelected)="monthSelected.emit($event)"
    ></app-filter>
  </div>

  <app-data-grid
    [label]="'Users'"
    *ngIf="list_type === 'grid'"
    [data]="data"
  ></app-data-grid>

  <table
    [ngClass]="{
      'with-paginate-table': total > data?.length,
      hidden: list_type !== 'table',
    }"
    [@stagger]="dataSource.filteredData"
    [dataSource]="dataSource"
    class="w-full flex-auto"
    mat-table
    matSort
    multiTemplateDataRows
  >
    <!--    (matSortChange)="changeSort($event)"-->
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Model Properties Column -->
    <ng-container *ngFor="let column of columns">
      <!--type text -->
      <ng-container
        *ngIf="column.type === 'text'"
        [matColumnDef]="column.property"
      >
        <ng-container *ngIf="column.property === 'status_object'; else others">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            [disabled]="!column.hasSort"
          >
            {{ column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <a
              class="teacher_badge cursor-default ltr:mr-3 rtl:ml-3"
              [style]="{ background: row[column.property]?.color }"
              >{{ row[column.property].text ?? '-' }}</a
            >
          </td>
        </ng-container>
        <ng-container *ngIf="column.property === 'color'; else others">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            [disabled]="!column.hasSort"
          >
            {{ column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <a
              class="teacher_badge cursor-default ltr:mr-3 rtl:ml-3"
              [style]="{
                background: row[column.property],
                width: '50%',
                height: '20px',
              }"
            ></a>
          </td>
        </ng-container>

        <!-- Results list -->
        <ng-container *ngIf="column.property === 'grade'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <mat-form-field
              class="position-relative results-input"
              style="width: 80px; height: 70px"
            >
              <input
                [value]="data[i] ? data[i]['grade'] : 0"
                (keyup)="change('Grade', $event, i)"
                matInput
                type="number"
                placeholder="{{ 'Grade' | translate }}"
              />
            </mat-form-field>
            <small
              *ngIf="invalidGrades[i]"
              class="text-danger position-absolute invalid-grade"
              >{{ 'Grade be negative or exceed' | translate }}
              {{ finalGrade }}</small
            >
          </td>
        </ng-container>
        <ng-container *ngIf="column.property === 'comment'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <mat-form-field
              class="mx-2 position-relative"
              style="width: 80px; height: 70px"
            >
              <input
                [value]="data[i] ? data[i]['comment'] : '-'"
                (keyup)="change('Comment', $event, i)"
                matInput
                type="text"
                placeholder="{{ 'Comment' | translate }}"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Accredit results -->
        <ng-container *ngIf="column.property === 'evaluationStatus'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td *matCellDef="let row; let i = dataIndex">
            <mat-select
              class="table-drop-down"
              (click)="$event.stopPropagation()"
              placeholder="{{ 'Evaluation status' | translate }}"
              [(ngModel)]="data[i]['evaluationStatusID']"
            >
              <mat-option
                *ngFor="let status of evaluationStatus"
                [value]="status?.id"
                >{{ status?.name }}</mat-option
              >
            </mat-select>
          </td>
        </ng-container>

        <ng-template #others>
          <ng-container>
            <th
              *matHeaderCellDef
              mat-header-cell
              mat-sort-header
              [ngClass]="column.cssClasses"
              [disabled]="!column.hasSort"
              (click)="headerClicked($event)"
            >
              {{ column.label | translate }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              tooltip="{{
                row[column.property]?.length > 20 ? row[column.property] : ''
              }}"
              mat-cell
            >
              {{
                row[column.property]?.length > 20 &&
                !column.cssClasses.includes('large-entry')
                  ? row[column.property].slice(0, 20) + '...'
                  : (row[column.property] ?? '-')
              }}
            </td>
          </ng-container>
        </ng-template>
      </ng-container>

      <!--type number-->
      <ng-container
        *ngIf="column.type === 'number'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property] ?? '--' | translate }}
        </td>
      </ng-container>

      <!--type bool-->
      <ng-container
        *ngIf="column.type === 'bool'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property] === 0 ? 'Yes' : ('No' | translate) }}
        </td>
      </ng-container>

      <!--type date-->
      <ng-container
        *ngIf="column.type === 'date'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ (row[column.property] | customDateFormat) || '-' }}
        </td>
      </ng-container>

      <!--type html-->
      <ng-container
        *ngIf="column.type === 'html'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td
          *matCellDef="let row"
          [ngClass]="column.cssClasses"
          tooltip="{{
            row[column.property]?.length > 40 ? row[column.property] : ''
          }}"
          [innerHTML]="
            row[column.property]?.length > 40
              ? row[column.property].slice(0, 40) + '...'
              : row[column.property]
          "
          mat-cell
        ></td>
      </ng-container>

      <!--type menu-->
      <ng-container
        *ngIf="column.type === 'menu'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td
          *matCellDef="let row"
          [ngClass]="column.cssClasses"
          [innerHTML]="row[column.property]"
          mat-cell
        ></td>
      </ng-container>

      <!--type multiple_text-->
      <ng-container
        *ngIf="column.type === 'multiple_text'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          <a
            class="teacher_badge ltr:mr-3 rtl:ml-3"
            routerLink="/admin-management/teachers/{{ teacher?.teacher_id }}"
            target="_blank"
            [style]="{ background: teacher?.status_object?.color }"
            [tooltip]="teacher?.status_object?.text"
            *ngFor="let teacher of row[column.property]"
            >{{ teacher?.status_object?.teacher_name }}</a
          >
        </td>
      </ng-container>

      <!--type badge-->
      <ng-container
        *ngIf="column.type === 'badge'"
        [matColumnDef]="column.property"
      >
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [disabled]="!column.hasSort"
        >
          {{ column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          <div
            *ngIf="row[column.property] === 'ready'"
            class="w-3 h-3 rounded-full bg-green cursor-pointer"
            tooltip="Ready to ship"
          ></div>
          <div
            *ngIf="row[column.property] === 'pending'"
            class="w-3 h-3 rounded-full bg-orange cursor-pointer"
            tooltip="Pending Payment"
          ></div>
          <div
            *ngIf="row[column.property] === 'warn'"
            class="w-3 h-3 rounded-full bg-red cursor-pointer"
            tooltip="Missing Payment"
          ></div>
        </td>
      </ng-container>

      <!-- type Checkbox -->
      <ng-container
        *ngIf="column.type === 'checkbox'"
        [matColumnDef]="column.property"
        sticky
      >
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            color="primary"
            *ngIf="
              (listName !== 'workforce' && listName !== 'workforceNew') ||
              (listName === 'workforce' && hasAllWorkForceToTransfer) ||
              (listName === 'workforceNew' && hasAllWorkForceToTransfer)
            "
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" class="w-4" mat-cell>
          <mat-checkbox
            (change)="$event ? selection.toggle(row) : null"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
            color="primary"
            *ngIf="
              (listName !== 'workforce' && listName !== 'workforceNew') ||
              (listName === 'workforce' && row?.send) ||
              (listName === 'workforceNew' && row?.send)
            "
          >
          </mat-checkbox>
          <div
            *ngIf="
              (listName === 'workforce' && !row?.send) ||
              (listName === 'workforceNew' && !row?.send)
            "
            matSuffix
            [matTooltip]="row.comment"
            matTooltipPosition="above"
          >
            <mat-icon svgIcon="mat:info" class="ms-2"></mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- type image-->
      <ng-container
        *ngIf="column.type === 'image'"
        [matColumnDef]="column.property"
      >
        <th *matHeaderCellDef mat-header-cell>
          {{ column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          <img
            [attr.src]="
              row[column.property] ? row[column.property] : defaultAvatar
            "
            class="avatar h-9 w-9 align-middle"
            alt="Kuwait Society of Engineers image"
          />
        </td>
      </ng-container>

      <!-- type button-->

      <ng-container
        *ngIf="column.type === 'button'"
        [matColumnDef]="column.property"
        stickyEnd
      >
        <ng-container *ngIf="column.property === 'menu'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10 rtl:left-0"
            mat-cell
          >
            <button
              (click)="$event.stopPropagation(); selectedRow = row"
              [matMenuTriggerFor]="actionMenu"
              mat-icon-button
              type="button"
            >
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'sendToNOC'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <mat-checkbox
              (click)="$event.stopPropagation(); sendToNoc(row)"
              [(ngModel)]="row[column.property]"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'status'">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            [disabled]="!column.hasSort"
          >
            {{ column.label | translate }}
          </th>
          <!--          <td *matCellDef="let row" [style.color]="row[column.property]?.color" [ngClass]="column.cssClasses"-->
          <!--              class="w-10" mat-cell>{{(row[column.property]?.name) || row[column.property]}}</td>-->
          <td
            *matCellDef="let row"
            [style.color]="row[column.property]?.color"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <button
              (click)="$event.stopPropagation(); selectedRow = row"
              [matMenuTriggerFor]="statusMenu"
              mat-icon-button
              type="button"
            >
              {{
                row[column.property]?.name || row[column.property] | translate
              }}
            </button>
          </td>
        </ng-container>

        <!-- Reception cachier collect -->
        <ng-container
          *ngIf="
            column.property === 'requests' && roles?.includes('CreateCasher')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o"
              [ngClass]="{ opener: clickedRow === row }"
              (click)="clickedRow = row; goToCachierCollect(row)"
            >
              <span class="mx-1">{{ 'Collect' | translate }}</span></i
            >
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'add_request'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="addRequestPaymentEvent(row)">
              <span class="mx-1">{{ 'add_request' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Issued certificate collect -->
        <ng-container *ngIf="column.property === 'PrintCertificate'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="printCertificateEvent(row)">
              <span class="mx-1">{{ 'PrintCertificate' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Go to company members -->
        <ng-container
          *ngIf="
            column.property === 'goCompanyMembers' &&
            roles?.includes('GetAllProfession')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="goToCompanyMembersEvent(row)">
              <span class="mx-1">{{ 'Comapny members' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <ng-container *ngIf="column.property === 'reviewPersonAboard'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="reviewPersonAboard(row)">
              <span class="mx-1">{{ 'Review' | translate }}</span></i
            >
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'replyVerifyCertificate'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="goToReplyVerifyCertificate(row)">
              <span class="mx-1">{{ 'Add reply' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <ng-container *ngIf="column.property === 'goAuditInfo'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="goAuditInfoEvent(row)">
              <span class="mx-1">{{ 'det' | translate }}</span></i
            >
          </td>
        </ng-container>

        <!-- Evaluation management panels list -->
        <ng-container *ngIf="column.property === 'documents'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
            tooltip="{{ 'Download all as pdf' | translate }}"
            (click)="downloadAllAsPdf(row, $event)"
          >
            <div
              class="d-flex align-items-center justify-content-center download_all"
            >
              <mat-icon class="mx-1">file_download</mat-icon>
              <span>{{ 'Download' | translate }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'panelMembers'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="goPanelMembersEvent(row)">
              <span>{{ 'Panel members' | translate }}</span></i
            >
          </td>
        </ng-container>
        <ng-container *ngIf="column.property === 'panelAllExaminers'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="goAllExaminersEvent(row)">
              <span class="mx-1">{{ 'Panel examiners' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Evaluation list -->
        <ng-container *ngIf="column.property === 'panelExaminers'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="goPanelExaminersEvent(row)">
              <span class="mx-1">{{ 'Panel examiners' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Membership and classification cards -->
        <ng-container *ngIf="column.property === 'printCard'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="printCard.emit(row)">
              <span class="mx-1">{{ 'Print card' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- classification certificates -->
        <ng-container *ngIf="column.property === 'PrintCertificate'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o" (click)="printCertificate.emit(row)">
              <span class="mx-1">{{ 'PrintCertificate' | translate }}</span></i
            >
          </td>
        </ng-container>
        <ng-container *ngIf="column.property === 'fieldEdit'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o"
              (click)="editField.emit(row)"
              *ngIf="row.editField"
            >
              <span class="mx-1">{{ 'Edit Field' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- classification stamps -->
        <ng-container *ngIf="column.property === 'receiveStamp'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <div
              class="d-flex align-items-center justify-content-center receiveStamp"
              (click)="receiveStamp.emit(row)"
            >
              <span class="mx-1">{{ 'Receive stamp' | translate }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M312 201.8c0-17.4 9.2-33.2 19.9-47C344.5 138.5 352 118.1 352 96c0-53-43-96-96-96s-96 43-96 96c0 22.1 7.5 42.5 20.1 58.8c10.7 13.8 19.9 29.6 19.9 47c0 29.9-24.3 54.2-54.2 54.2H112C50.1 256 0 306.1 0 368c0 20.9 13.4 38.7 32 45.3V464c0 26.5 21.5 48 48 48H432c26.5 0 48-21.5 48-48V413.3c18.6-6.6 32-24.4 32-45.3c0-61.9-50.1-112-112-112H366.2c-29.9 0-54.2-24.3-54.2-54.2zM416 416v32H96V416H416z"
                />
              </svg>
            </div>
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Evaluation faculty members list -->
        <ng-container *ngIf="column.property === 'addPFMember'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o"
              (click)="addPMemberEvent(row, i)"
              *ngIf="
                (!inClassifications &&
                  roles?.includes('CreateFacultyMemberPanel')) ||
                (inClassifications &&
                  roles?.includes('BoShowClassifPanelFacultyMShipAdd'))
              "
            >
              <span class="mx-1">{{ 'Add member' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Add examiner list -->
        <ng-container *ngIf="column.property === 'addExaminer'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle"
              (click)="addExaminerEvent(row, i)"
            >
              <span class="mx-1">{{ 'Add to this panel' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Add old persons to panel -->
        <ng-container *ngIf="column.property === 'addOldToPanel'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle"
              (click)="addOldPersonToPanel(row, i)"
            >
              <span class="mx-1">{{ 'Add' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!--classification review data -->
        <ng-container *ngIf="column.property === 'addService'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle"
              (click)="addClassificationService(row, i)"
            >
              <span class="mx-1">{{ 'Add service' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Remove examiner list -->
        <ng-container *ngIf="column.property === 'removeExaminer'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle"
              (click)="removeExaminerEvent(row, i)"
            >
              <span class="mx-1">{{ 'Remove from panel' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- Accredit results -->
        <ng-container
          *ngIf="
            column.property === 'accredit' &&
            roles?.includes('CreateEvaluation')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle"
              (click)="$event.stopPropagation(); accreditResultsEvent(row)"
            >
              <span class="mx-1">{{ 'Accredit' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!--shared review user-->
        <ng-container
          *ngIf="
            column.property === 'review' &&
            roles.includes('BoShowCandidateReview')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o add_circle" (click)="reviewUser(row)">
              <span class="mx-1">{{ 'Review' | translate }}</span></i
            >
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.property === 'block' && roles.includes('blockedUser')"
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <mat-slide-toggle
              (change)="toggleBlock($event, row)"
              class="example-margin"
              color="primary"
              [checked]="row?.isBlocked"
              [disabled]="false"
            >
            </mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.property === 'classification' &&
            roles.includes('BoShowInquiriesClassification')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle"
              (click)="classificationDetails.emit(row)"
            >
              <span class="mx-1">{{ 'Classification' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-------------------------------->

        <!-- final reviews -->
        <ng-container *ngIf="column.property === 'transferDocs'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o add_circle review-com-docs-1"
              (click)="transferRow(row, 'documents')"
            >
              <span
                class="mx-1 review-com-docs"
                tooltip="{{ 'Transfer to Completing documents' | translate }}"
                >{{ 'Transfer to Completing documents' | translate }}</span
              ></i
            >
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'transfer_audition'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <button [matMenuTriggerFor]="options" class="drop-btn">
              {{ column.label | translate }}
              <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
            </button>
            <mat-menu #options="matMenu">
              <button
                *ngIf="roles?.includes('PersonTest')"
                mat-menu-item
                (click)="transferRow(row, 'test')"
              >
                {{ 'Evaluation (Exam)' | translate }}
              </button>
              <button
                *ngIf="roles?.includes('PersonInReview')"
                mat-menu-item
                (click)="transferRow(row, 'interview')"
              >
                {{ 'Evaluation (Interview)' | translate }}
              </button>
              <button
                *ngIf="roles?.includes('ToMembershipInterview')"
                mat-menu-item
                (click)="transferRow(row, 'membershipGrant')"
              >
                {{ 'Evaluation (membership grant interview)' | translate }}
              </button>
              <button
                *ngIf="roles?.includes('NoNeedEvaluation')"
                mat-menu-item
                (click)="transferRow(row, 'noNeedEvaluation')"
              >
                {{ 'No need for evaluation' | translate }}
              </button>
              <!-- <button *ngIf="roles?.includes('PresentInterview')" mat-menu-item (click) = 'transfer(row, "manager")'>{{'Transfer to manager' | translate}} </button> -->
            </mat-menu>
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'acceptance'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
            style="width: 85px !important"
          >
            <button [matMenuTriggerFor]="options" class="drop-btn">
              {{ column.label | translate }}
              <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
            </button>
            <mat-menu #options="matMenu">
              <button
                *ngIf="roles?.includes('AcceptPerson') && !hideAcceptance"
                mat-menu-item
                (click)="acceptance(row, 'Accepted')"
              >
                {{ 'Accepted' | translate }}
              </button>
              <button
                *ngIf="
                  roles?.includes('RecruitmentFromAbroad') && !hideAcceptance
                "
                mat-menu-item
                (click)="acceptance(row, 'Accepted(Admission)')"
              >
                {{ 'Accepted(Admission)' | translate }}
              </button>
              <button
                *ngIf="roles?.includes('RejectPerson')"
                mat-menu-item
                (click)="acceptance(row, 'Reject')"
              >
                {{ 'Reject' | translate }}
              </button>
              <button
                *ngIf="roles?.includes('FinalRejectPerson')"
                mat-menu-item
                (click)="acceptance(row, 'Ban')"
              >
                {{ 'Ban' | translate }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'requiredDocs'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o add_circle" (click)="goRequiredDocs(row)">
              <span class="mx-1">{{ 'Open' | translate }}</span></i
            >
          </td>
        </ng-container>

        <ng-container *ngIf="column.property === 'pageContent'">
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i class="fa fa-send-o add_circle" (click)="goSitePageContent(row)">
              <span class="mx-1">{{ 'Open' | translate }}</span></i
            >
          </td>
        </ng-container>
        <!-- profession and subprofession-->
        <ng-container
          *ngIf="
            column.property === 'verifyNoc' &&
            roles.includes('UpdateProfessionToNOC')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o"
              (click)="verifyNoc.emit(row)"
              *ngIf="row.verifyNoc"
            >
              <span class="mx-1">{{ 'Verify Noc' | translate }}</span></i
            >
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.property === 'verifyNocSub' &&
            roles.includes('UpdateSubProfessionToNOC')
          "
        >
          <th *matHeaderCellDef mat-header-cell>
            {{ column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <i
              class="fa fa-send-o"
              (click)="verifyNoc.emit(row)"
              *ngIf="row.verifyNocSub"
            >
              <span class="mx-1">{{ 'Verify Noc' | translate }}</span></i
            >
          </td>
        </ng-container>

        <!-------------------------------->
        <ng-container *ngIf="column.property === 'details'">
          <th *matHeaderCellDef mat-header-cell></th>
          <td
            *matCellDef="let row; let i = dataIndex"
            [ngClass]="column.cssClasses"
            class="w-10"
            mat-cell
          >
            <ng-container *ngIf="i !== 0">
              <i class="fa fa-send-o add_circle">
                <span class="mx-1">{{ 'Detail' | translate }}</span></i
              >
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- details -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="visibleColumns?.length"
        style="padding: 0"
      >
        <div
          class="px-4"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <app-data-table
            *ngIf="element?.subTableData?.length"
            [hasAdd]="false"
            [has_nav]="false"
            [hasDelete]="false"
            [hasModify]="false"
            [expandedTable]="true"
            [columns]="element?.subDisplayedColumns"
            [data]="element?.subTableData"
            [total]="element?.subTableData?.length"
            [pluralTableLabel]="''"
            [singleTableLabel]="''"
            [pagination]="'noPagination'"
          >
          </app-data-table>
          <!--          <table mat-table style="background: #cacaca"-->
          <!--                 [dataSource]="(element?.auditEntriesModelDetails || element?.evaluationGrade)"-->
          <!--                 multiTemplateDataRows class="mat-elevation-z8" *ngIf="subDisplayedColumns">-->

          <!--            <ng-container matColumnDef="{{column}}" *ngFor="let column of subDisplayedColumns?.values; let i = index">-->
          <!--              <th class="example-header-row" mat-header-cell-->
          <!--                  *matHeaderCellDef> {{subDisplayedColumns?.labels[i] | translate}} </th>-->
          <!--              <td mat-cell *matCellDef="let row"> {{row[column] ?? ' - ' | translate}} </td>-->
          <!--            </ng-container>-->

          <!--            <ng-container matColumnDef="{{sub_column}}"-->
          <!--                          *ngFor="let sub_column of subDisplayedColumns?.values; let i = index">-->
          <!--              <th *matHeaderCellDef mat-header-cell mat-sort-header [disabled]="!sub_column.hasSort"-->
          <!--                  (click)="headerClicked($event)"> {{ sub_column.label | translate}}</th>-->
          <!--              <td *matCellDef="let sub_row" [ngClass]="sub_column.cssClasses"-->
          <!--                  tooltip="{{sub_row[sub_column.property]?.length > 20 ? (sub_row[sub_column.property]) : ''}}"-->
          <!--                  mat-cell>-->
          <!--                {{sub_row[sub_column.property]?.length > 20 ? (sub_row[sub_column.property].slice(0, 20) + '...') :-->
          <!--                ((sub_row[sub_column.property]) || '-')}}</td>-->
          <!--            </ng-container>-->

          <!--            <tr mat-header-row *matHeaderRowDef="subDisplayedColumns"></tr>-->
          <!--            <tr mat-row *matRowDef="let element; columns: subDisplayedColumns" class="example-element-row">-->
          <!--            </tr>-->
          <!--          </table>-->
        </div>
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="visibleColumns; sticky: true"
      mat-header-row
      [ngStyle]="{ 'background-color': '#c5c5c2' }"
    ></tr>
    <!--suppress UnnecessaryLabelJS -->
    <tr
      [ngStyle]="{
        'background-color':
          rowIndex % 2 !== 0 ? '#fff' : 'var(--light-grey-color)',
      }"
      *matRowDef="let row; columns: visibleColumns; let rowIndex = dataIndex"
      @fadeInUp
      [ngClass]="{
        selected:
          (listName !== 'workforce' && selection.isSelected(row)) ||
          (listName !== 'workforceNew' && selection.isSelected(row)) ||
          (listName === 'workforce' &&
            row?.send &&
            selection.isSelected(row)) ||
          (listName === 'workforceNew' &&
            row?.send &&
            selection.isSelected(row)),
      }"
      class="hover:bg-hover cursor-pointer example-element-row"
      mat-row
      (click)="rowClicked(row)"
      [class.example-expanded-row]="expandedElement === row"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>

  <div
    *ngIf="dataSource.filteredData.length === 0 && !loading"
    @scaleFadeIn
    class="flex-auto flex flex-col items-center justify-center"
  >
    <img class="mt-4 mb-2 h-64" src="assets/img/illustrations/idea.svg" />
    <h2 class="headline m-0 text-center mb-2">
      {{
        (filtering ? 'No data matching your filters' : 'No data available')
          | translate
      }}
    </h2>
  </div>

  <mat-paginator
    [class.hidden]="dataSource.filteredData.length === 0"
    [pageSize]="pageSize"
    [pageIndex]="pageIndexNum"
    [length]="total"
    [showFirstLastButtons]="true"
    [pageSizeOptions]="pageSizeOptions"
    (page)="changePage($event)"
    id="paginator"
    class="bottom-0 ltr:left-0 rtl:right-0 table-paginator border-t flex-none"
    *ngIf="pagination !== 'noPagination'"
  ></mat-paginator>
</div>
<mat-menu #actionMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    *ngIf="hasModify && checkRoles('Update')"
    (click)="openRow.emit(selectedRow)"
  >
    <mat-icon svgIcon="mat:edit"></mat-icon>
    <span>{{ 'Modify' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="hasDelete && checkRoles('Delete')"
    (click)="deleteData.emit([selectedRow])"
  >
    <mat-icon svgIcon="mat:delete_forever"></mat-icon>
    <span>{{ 'Delete' | translate }}</span>
  </button>

  <button mat-menu-item *ngIf="hasRePrint" (click)="goToReprint(selectedRow)">
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>{{ 'Re-print' | translate }}</span>
  </button>

  <button mat-menu-item *ngIf="hasView" (click)="view(selectedRow)">
    <mat-icon svgIcon="mat:visibility"></mat-icon>
    <span>{{ 'View' | translate }}</span>
  </button>

  <button mat-menu-item *ngIf="hasCollege" (click)="gotoColleges(selectedRow)">
    <mat-icon svgIcon="mat:settings"></mat-icon>
    <span>{{ 'College' | translate }}</span>
  </button>

  <!-- Reception data print and transfer -->
  <button
    mat-menu-item
    *ngIf="hasPrintReceipt"
    (click)="printReviewData(selectedRow, 'receipt')"
  >
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>{{ 'Print receipt' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="hasPrintBarcode"
    (click)="printReviewData(selectedRow, 'barcode')"
  >
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>{{ 'Print qr' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="selectedRow?.isTransferToUser && hasTransformToUser"
    (click)="transferPersonData(selectedRow, 'toUser')"
  >
    <mat-icon svgIcon="mat:move_down"></mat-icon>
    <span>{{ 'Transfer to user' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="selectedRow?.isTransferToCompany && hasTransformToCompany"
    (click)="transferPersonData(selectedRow, 'toCompany')"
  >
    <mat-icon svgIcon="mat:move_up"></mat-icon>
    <span>{{ 'Transfer to company' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="selectedRow?.isUnlink && hasUnlink"
    (click)="transferPersonData(selectedRow, 'toUnlink')"
  >
    <mat-icon svgIcon="mat:link_off"></mat-icon>
    <span>{{ 'Unlink' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="hasTransformToCompletingDocuments"
    (click)="transferEvent(selectedRow, 'documents')"
  >
    <mat-icon svgIcon="mat:move_up"></mat-icon>
    <span>{{ 'Transfer to Completing documents' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="hasAddToCompany && roles?.includes('SetPersonDataToCompany')"
    (click)="addUserToCompany(selectedRow)"
  >
    <mat-icon svgIcon="mat:move_up"></mat-icon>
    <span>{{ 'Add to company' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="roles?.includes('BoShowDocumentRequierd') && hasDataReviewTransfer"
    (click)="dataReviewTransfer(selectedRow, 'documents')"
  >
    <mat-icon svgIcon="mat:move_up"></mat-icon>
    <span>{{ 'Transfer to Completing documents' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="
      roles?.includes('BoShowReceptionToFinalReview') &&
      roles?.includes('BoShowReceptionToInvestCompany') &&
      selectedRow?.isHaveApplicationform &&
      hasDataReviewTransfer
    "
    (click)="dataReviewTransfer(selectedRow, 'invest/final')"
  >
    <mat-icon svgIcon="mat:move_up"></mat-icon>
    <span>{{ 'Certificate verification/Final revision' | translate }}</span>
  </button>

  <button
    (click)="uploadAppForm(selectedRow)"
    mat-menu-item
    *ngIf="!selectedRow?.isHaveApplicationform && hasDataReviewTransfer"
  >
    <mat-icon svgIcon="mat:warning"></mat-icon>
    <span>{{ 'Add application form' | translate }}</span>
  </button>
  <button
    (click)="uploadAppForm(selectedRow)"
    mat-menu-item
    *ngIf="hasUploadAppForm"
  >
    <mat-icon svgIcon="mat:upload_file"></mat-icon>
    <span>{{ 'Add Audit form' | translate }}</span>
  </button>
  <!-------------------------------------------------------->

  <!-- Re-evaluate -->
  <button
    mat-menu-item
    *ngIf="hasTransferTestToExam"
    (click)="transferTestReviewEvent(selectedRow, 'toExam')"
  >
    <span>{{ 'Evaluation (Exam)' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasTransferTestToInterview"
    (click)="transferTestReviewEvent(selectedRow, 'toInterview')"
  >
    <span>{{ 'Evaluation (Interview)' | translate }}</span>
  </button>
  <!-------------------------------------------------------->

  <!-- Accounting service request -->
  <button
    mat-menu-item
    *ngIf="
      roles?.includes('DeleteServiceRequest') && hasAccServiceRequestDelete
    "
    (click)="deleteData.emit([selectedRow])"
  >
    <mat-icon svgIcon="mat:delete_forever"></mat-icon>
    <span>{{ 'Delete' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="selectedRow?.showCancelAcrredit && hasAccServiceRequestCancelAcc"
    (click)="cancelAccreditationEvent(selectedRow)"
  >
    <mat-icon svgIcon="mat:cancel"></mat-icon>
    <span>{{ 'Cancel accreditation' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="
      roles?.includes('UpdateServiceRequestForFree') && hasAccServiceRequestAcc
    "
    (click)="updateForFreeEvent(selectedRow)"
  >
    <mat-icon svgIcon="mat:check_box"></mat-icon>
    <span>{{ 'Accredit for free' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="editAddSubtable"
    (click)="editPersonStatus.emit(selectedRow)"
  >
    <mat-icon svgIcon="mat:edit"></mat-icon>
    <span>{{ 'Edit' | translate }}</span>
  </button>

  <button
    *ngIf="hasRequestStatus"
    mat-menu-item
    [matMenuTriggerFor]="requestStatusRef"
  >
    {{ 'Request status' | translate }}
  </button>

  <mat-menu #requestStatusRef="matMenu">
    <ng-container *ngFor="let status of requestStatus; let i = index">
      <!-- rest remains same -->
      <button
        [ngStyle]="{
          'font-weight':
            selectedRow?.requestStatusId === status.id ? 'bold' : 'normal',
        }"
        mat-menu-item
        (click)="changeReqStatus(selectedRow, status.id, i)"
      >
        {{ status?.name }}
      </button>
    </ng-container>
  </mat-menu>
  <!-------------------------------------------------------->

  <!--  classifications requests -->
  <button
    mat-menu-item
    *ngIf="hasClassificationsRequestDelete"
    (click)="deleteData.emit([selectedRow])"
  >
    <mat-icon svgIcon="mat:delete_forever"></mat-icon>
    <span>{{ 'Delete' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasClassificationsCard"
    (click)="printCard.emit(selectedRow)"
  >
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>{{ 'Print card' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasClassificationsCertificate"
    (click)="printCertificate.emit(selectedRow)"
  >
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>{{ 'PrintCertificate' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="hasReview && roles?.includes('BoShowCandidateReview')"
    (click)="reviewUser(selectedRow)"
  >
    <mat-icon svgIcon="mat:remove_red_eye"></mat-icon>
    <span>{{ 'Review' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="
      hasClassification && roles?.includes('BoShowInquiriesClassification')
    "
    (click)="classificationDetails.emit(selectedRow)"
  >
    <mat-icon svgIcon="mat:segment"></mat-icon>
    <span>{{ 'Classification' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasPrintInquire && roles?.includes('BoShowInquiriesPrint')"
    (click)="printReviewData(selectedRow, 'receipt')"
  >
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>{{ 'Print receipt' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasUpdatePersonalInfo"
    (click)="takeWorkforceAction(selectedRow, 'updatePersonalInfo')"
  >
    <mat-icon svgIcon="mat:update"></mat-icon>
    <span>{{ 'Update personal info' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasUpdateDocuments"
    (click)="takeWorkforceAction(selectedRow, 'updateDocs')"
  >
    <mat-icon svgIcon="mat:update"></mat-icon>
    <span>{{ 'Update documents' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasUpdateClassification"
    (click)="takeWorkforceAction(selectedRow, 'updateClassification')"
  >
    <mat-icon svgIcon="mat:update"></mat-icon>
    <span>{{ 'Update classification' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="selectedRow?.showCancelAcrredit && hasClassificationRequestCancel"
    (click)="cancelAccreditationEvent(selectedRow)"
  >
    <mat-icon svgIcon="mat:cancel"></mat-icon>
    <span>{{ 'Cancel accreditation' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasClassificationRequestApproveForFree"
    (click)="updateForFreeEvent(selectedRow)"
  >
    <mat-icon svgIcon="mat:check_box"></mat-icon>
    <span>{{ 'Accredit for free' | translate }}</span>
  </button>

  <button
    *ngIf="hasClassificationRequestStatus"
    mat-menu-item
    [matMenuTriggerFor]="classRequestStatusRef"
  >
    {{ 'Request status' | translate }}
  </button>

  <mat-menu #classRequestStatusRef="matMenu">
    <ng-container *ngFor="let status of requestStatus; let i = index">
      <!-- rest remains same -->
      <button
        *ngIf="!(status.id === 2 && !roles?.includes('BoShowClassifApprove'))"
        [ngStyle]="{
          'font-weight':
            selectedRow?.requestStatusId === status.id ? 'bold' : 'normal',
        }"
        mat-menu-item
        (click)="changeReqStatus(selectedRow, status.id, i)"
      >
        {{ status?.name }}
      </button>
    </ng-container>
  </mat-menu>
  <!--  classifications requests -->

  <!-- File management -->
  <button
    *ngIf="hasAvailStatus && selectedRow?.availableStatuesType"
    mat-menu-item
    [matMenuTriggerFor]="availStatusRef"
  >
    {{ 'Change status' | translate }}
  </button>

  <span
    tooltip="{{ 'No available status' | translate }}"
    class="text-danger fw-bold"
    *ngIf="hasAvailStatus && !selectedRow?.availableStatuesType"
    >{{ 'No available status' | translate }}</span
  >

  <mat-menu #availStatusRef="matMenu">
    <ng-container
      *ngFor="let status of selectedRow?.availableStatuesType; let i = index"
    >
      <!-- rest remains same -->
      <button
        [ngStyle]="{
          'font-weight':
            selectedRow?.selectedRow?.availableStatuesTypeId === status.id
              ? 'bold'
              : 'normal',
        }"
        mat-menu-item
        (click)="changePersonStatusEvent(selectedRow, status.id)"
      >
        {{ status?.name }}
      </button>
    </ng-container>
  </mat-menu>
  <!-------------------------------------------------------->
</mat-menu>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of filterColumns"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="changeStatus(status)"
    *ngFor="let status of statuses"
    class="checkbox-item mat-menu-item"
  >
    {{ status?.name | translate }}
  </button>
</mat-menu>
