<div class="certificate-pr" *ngIf="!loading; else loader">
  <div fxLayout="row" fxLayoutAlign="center" style="padding: 0.5rem 2rem">
    <button
      (click)="print()"
      printSectionId="{{ print_id }}"
      ngxPrint
      mat-button
      mat-raised-button
      [useExistingCss]="true"
      color="primary"
      class="mat-focus-indicator mat-primary print-button"
      style=""
    >
      {{ 'PrintCertificate' | translate }}
    </button>
  </div>
  <div
    *ngIf="
      printCertificatData?.kseIssuedCertType?.actionCode === 'ToWhomeNeedCert'
    "
    id="ToWhomeNeedCert"
    class="px-4"
  >
    <div
      class="print-content"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      style="width: 100%; height: 100%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="outer-border w-100">
          <div class="inner-dotted-border">
            <div
              class="mb-0 position-relative"
              fxLayout="row wrap"
              fxLayoutAlign="space-between start"
            >
              <div style="width: 45%; text-align: start">
                <span class="certify">
                  <span class="header-info">التاريخ : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.issueDate
                  }}</span>
                </span>
              </div>
            </div>
            <span class="certification">شهاده عضوية</span>
            <br /><br />
            <div class="mt-2" fxLayout="row wrap">
              <div fxFlex="100" class="d-flex justify-content-start">
                <span class="certify">
                  <span class="font-bold"
                    >تشهد جمعية المهندسين الكويتية&nbsp;</span
                  ><span>&nbsp; بأن السيد/&nbsp;</span
                  ><span class="name">{{
                    printCertificatData?.arabicName
                  }}</span>
                </span>
              </div>
            </div>
            <div class="" fxLayout="column wrap" fxLayoutAlign="start center">
              <div class="w-100 d-flex my-0">
                <span class="certify gaped">
                  <span style="font-size: 22px"
                    >ويحمل بطاقة مدنية رقم&nbsp;:&nbsp;</span
                  >
                  <span style="font-size: 22px">{{
                    printCertificatData?.civilID
                  }}</span>
                </span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%"
              >
                <div style="width: fit-content">
                  <span class="certify">
                    <span style="font-size: 22px"
                      >مسجل لديها كعضو منتسب بتاريخ&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{ printCertificatData?.startDate }} &nbsp;</span
                    >
                  </span>
                </div>
                <div style="width: fit-content" class="certify gaped">
                  <span style="font-size: 22px">تخصص&nbsp;:&nbsp; </span>
                  <span style="font-size: 22px">{{
                    printCertificatData?.profession
                  }}</span>
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start"
                style="width: 100%; gap: 113px"
              >
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >ورقم عضويته
                      <span *ngIf="membership_type === 'permanentMembership'"
                        >الدائمة</span
                      >
                      &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.personMembership.membershipNum
                      }}
                      &nbsp;</span
                    >
                  </span>
                </div>

                <div
                  style="width: fit-content"
                  class="row my-0"
                  *ngIf="membership_type !== 'permanentMembership'"
                >
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تاريخ الإنتهاء &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{ printCertificatData?.endDate }} &nbsp;</span
                    >
                  </span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start " style="width: 100%">
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >وبناءا علي طلبه اعطيت له هذه الشهادة لتقديمها الى &nbsp;:
                      &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{ printCertificatData?.organization }} &nbsp;</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start my-0 w-100"
                fxLayout="row wrap"
                fxLayoutAlign="center"
              >
                <div>
                  <span style="font-size: 22px; text-align: right"
                    >دون ادنى مسئولية على الجمعية تجاه الغير، علما بأن هذه
                    الشهادة صالحة لمدة ثلاثة أشهر من تاريخ الإصدار.</span
                  >
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: start"
                  class="my-0"
                >
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >رقم الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptNum
                    }}</span>
                  </span>
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تاريخ الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptDate
                    }}</span>
                  </span>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: center; padding-top: 50px"
                  class=""
                >
                  <span class="certify">
                    <span
                      class="fs-30 w-100 font-bold signature"
                      style="font-size: 23px"
                      >جمعية المهندسين الكويتية</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 20%; text-align: center"
                >
                  <qrcode
                    id="qr"
                    [elementType]="qr_elementType"
                    [qrdata]="qrdata"
                    [scale]="scale"
                    [width]="qr_width"
                  ></qrcode>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 30%; text-align: center"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      printCertificatData?.kseIssuedCertType?.actionCode ===
      'ToWhomeNeedCertNOC'
    "
    id="ToWhomeNeedCertNOC"
    class="px-4"
  >
    <div
      class="print-content"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      style="width: 100%; height: 100%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="outer-border w-100">
          <div class="inner-dotted-border">
            <div class="waterMark">
              {{ 'society copy' | translate }}
            </div>
            <div
              class="mb-0 position-relative"
              fxLayout="row wrap"
              fxLayoutAlign="space-between start"
            >
              <div style="width: 45%; text-align: start">
                <span class="certify">
                  <span class="header-info">التاريخ : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.issueDate
                  }}</span>
                </span>
              </div>
              <div class="col-sm-3">
                <!-- <img class="w-100" src="{{ logo }}" /> -->
                <span class="certify">
                  <span class="header-info">رقم الشهادة : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.certificateId
                  }}</span>
                </span>
              </div>
            </div>
            <span class="certification">شهادة الى من يهمه الامر</span>
            <br /><br />
            <div class="mt-2" fxLayout="row wrap">
              <div fxFlex="100" class="d-flex justify-content-start">
                <span class="certify">
                  <span class="font-bold"
                    >تشهد جمعية المهندسين الكويتية&nbsp;</span
                  ><span>&nbsp; بأن السيد/&nbsp;</span
                  ><span class="name">{{
                    printCertificatData?.arabicName
                  }}</span>
                </span>
              </div>
            </div>
            <div class="" fxLayout="column wrap" fxLayoutAlign="start center">
              <div class="w-100 d-flex my-0">
                <span class="certify gaped">
                  <span style="font-size: 22px"
                    >ويحمل بطاقة مدنية رقم&nbsp;:&nbsp;</span
                  >
                  <span style="font-size: 22px">{{
                    printCertificatData?.civilID
                  }}</span>
                </span>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%"
              >
                <div style="width: fit-content" class="my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px">الجنسية &nbsp;: &nbsp;</span>
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.nationalityL
                      }}
                      &nbsp;&nbsp;&nbsp;</span
                    >
                  </span>
                </div>
                <div style="width: fit-content" class="certify gaped">
                  <span style="font-size: 22px"
                    >جواز سفر رقم&nbsp;:&nbsp;
                  </span>
                  <span style="font-size: 22px">{{
                    printCertificatData?.passportNum
                  }}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start" style="width: 100%">
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تخصص رئيسي &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.profession
                      }}
                      &nbsp;&nbsp;&nbsp;</span
                    >
                  </span>
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start "
                style="width: 100%"
                class="my-0"
              >
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تخصص فرعي &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{ printCertificatData?.subProfession }} &nbsp;</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100 my-0"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
              >
                <div class="my-0">
                  <span class="certify">
                    <span style="font-size: 22px"
                      >قد اجتاز الإختبار بتاريخ&nbsp;:&nbsp;
                    </span>
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.personEvalMinDate
                          ? printCertificatData?.personEvalMinDate
                          : printCertificatData?.personStatusMinDate
                      }}&nbsp;</span
                    >
                  </span>
                </div>
                <div>
                  <span style="font-size: 22px"
                    >وذلك للعمل بمهنة (
                    <strong
                      ><span *ngIf="!architectural">مهندس</span>&nbsp;<span
                        *ngIf="architectural"
                        >{{ 'architectural' | translate }}</span
                      >
                    </strong>
                    )
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100 my-0"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
              >
                <div class="my-0">
                  <span style="font-size: 22px; text-align: right" class="my-0">
                    هذا وقد اعطيت له هذه الشهادة بناءًا على طلبه لتقديمها الى
                    الهيئه العامه للقوى العامله
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100 my-0"
                fxLayout="row wrap"
                fxLayoutAlign="center"
              >
                <div class="my-0">
                  <span style="font-size: 22px">
                    دون ادنى مسئولية على الجمعية تجاه الغير.
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
              >
                <div>
                  <span
                    style="
                      font-size: 22px;
                      font-weight: 600;
                      text-decoration: underline;
                    "
                  >
                    علما بأن هذه الشهادة صالحة لمدة ثلاثة أشهر من تاريخ الإصدار.
                  </span>
                </div>
              </div>

              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: start"
                  class="my-0"
                >
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >رقم الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptNum
                    }}</span>
                  </span>
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تاريخ الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptDate
                    }}</span>
                  </span>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: center; padding-top: 50px"
                  class=""
                >
                  <span class="certify">
                    <span
                      class="fs-30 w-100 font-bold signature"
                      style="font-size: 23px"
                      >جمعية المهندسين الكويتية</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 20%; text-align: center"
                >
                  <qrcode
                    id="qr-2"
                    [elementType]="qr_elementType"
                    [qrdata]="qrdata"
                    [scale]="scale"
                    [width]="qr_width"
                  ></qrcode>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 30%; text-align: center"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      printCertificatData?.kseIssuedCertType?.actionCode ===
      'ToWhomeNeedCertOutside'
    "
    id="ToWhomeNeedCertOutside"
    class="px-4"
  >
    <div
      class="print-content"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      style="width: 100%; height: 100%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="outer-border w-100">
          <div class="inner-dotted-border">
            <div
              class="mb-0 position-relative"
              fxLayout="row wrap"
              fxLayoutAlign="space-between start"
            >
              <div style="width: 45%; text-align: start" class="my-0">
                <span class="certify">
                  <span class="header-info">التاريخ : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.issueDate
                  }}</span>
                </span>
              </div>
              <div class="col-sm-3 text-to-left">
                <!-- <img class="w-100" src="{{ logo }}" /> -->
                <span class="certify">
                  <span class="header-info">رقم الشهادة : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.certificateId
                  }}</span>
                </span>
              </div>
            </div>
            <span class="certification">شهادة الى من يهمه الامر - (مؤقته)</span>
            <br /><br />
            <div class="mt-2" fxLayout="row wrap">
              <div fxFlex="100" class="d-flex justify-content-start">
                <span class="certify">
                  <span class="font-bold"
                    >تشهد جمعية المهندسين الكويتية&nbsp;</span
                  ><span>&nbsp; بأن السيد/&nbsp;</span
                  ><span class="name">{{
                    printCertificatData?.arabicName
                  }}</span>
                </span>
              </div>
            </div>
            <div class="" fxLayout="column wrap" fxLayoutAlign="start center">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%"
                class="d-flex flex-column w-100 align-items-start"
              >
                <div style="width: fit-content" class="my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px">الجنسية &nbsp;: &nbsp;</span>
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.nationalityL
                      }}
                      &nbsp;&nbsp;&nbsp;</span
                    >
                  </span>
                </div>
                <div style="width: fit-content" class="certify gaped my-0">
                  <span style="font-size: 22px"
                    >جواز سفر رقم&nbsp;:&nbsp;
                  </span>
                  <span style="font-size: 22px">{{
                    printCertificatData?.passportNum
                  }}</span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start" style="width: 100%">
                <div style="width: fit-content" class="row my-0">
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تخصص رئيسي &nbsp;: &nbsp;</span
                    >
                    <span style="font-size: 22px"
                      >{{
                        printCertificatData?.profession
                      }}
                      &nbsp;&nbsp;&nbsp;</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex align-items-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
              >
                <p style="font-size: 22px" class="w-100 d-flex mb-3">
                  ولا مانع من منح حاملها شهادة لمن يهمه الأمر من الجمعية بالشروط
                  التالية:
                </p>
                <div class="d-flex justify-content-start flex-column w-100">
                  <p
                    class="d-flex justify-content-start mb-3"
                    style="font-size: 22px"
                  >
                    أولاً: تقديم المستندات المطلوبة للحصول على شهادة لمن يهمه
                    الأمر وهي:
                  </p>
                  <ol class="d-flex justify-content-start flex-column">
                    <li class="m-0 p-0 text-end">
                      ١- الشهادة الجامعيه الاصلية مصدقه من سفارة دولته ومن ثم
                      الخارجية الكويتية.
                    </li>
                    <li class="m-0 p-0 text-end">
                      ٢- كشف درجات معتمد من جامعته.
                    </li>
                    <li class="m-0 p-0 text-end">٣- صورتين شخصيتين.</li>
                  </ol>
                  <p class="d-flex my-0" style="font-size: 22px">
                    ثانيًا: إجتيازه بنجاح الإختبارات التي تعقد في جمعية
                    المهندسين الكويتية.
                  </p>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100 my-0"
                fxLayout="row wrap"
                fxLayoutAlign="start center"
              >
                <div class="my-0">
                  <span class="my-0" style="font-size: 22px; text-align: right">
                    وأعطيت له هذه الشهادة دون أدنى مسئولية تجاه الجمعية.
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
              >
                <div>
                  <span
                    style="
                      font-size: 22px;
                      font-weight: 600;
                      text-decoration: underline;
                    "
                  >
                    علما بأن هذه الشهادة صالحة لمدة ستة أشهر من تاريخ الإصدار.
                  </span>
                </div>
              </div>

              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: start"
                  class="my-0"
                >
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >رقم الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptNum
                    }}</span>
                  </span>
                  <span class="certify gaped">
                    <span style="font-size: 22px"
                      >تاريخ الإيصال&nbsp;:&nbsp;</span
                    >
                    <span style="font-size: 22px">{{
                      printCertificatData?.receiptDate
                    }}</span>
                  </span>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 50%; text-align: center; padding-top: 50px"
                  class=""
                >
                  <span class="certify">
                    <span
                      class="fs-30 w-100 font-bold signature"
                      style="font-size: 23px"
                      >جمعية المهندسين الكويتية</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="d-flex justify-content-start w-100"
                fxLayout="row wrap"
                fxLayoutAlign="space-between space-between"
              >
                <div
                  fxLayout="column wrap"
                  style="width: 20%; text-align: center"
                >
                  <qrcode
                    id="qr-2"
                    [elementType]="qr_elementType"
                    [qrdata]="qrdata"
                    [scale]="scale"
                    [width]="qr_width"
                  ></qrcode>
                </div>
                <div
                  fxLayout="column wrap"
                  style="width: 30%; text-align: center"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      printCertificatData?.kseIssuedCertType?.actionCode ===
      'ToWhomeNeedCertProfEng'
    "
    id="ToWhomeNeedCertProfEng"
    class="px-4"
  >
    <div
      class="print-content"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      style="width: 100%; height: 100%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          class="outer-border w-100"
          [ngClass]="{
            'large-padding':
              printCertificatData.kseIssuedCertType.actionCode ===
              'ToWhomeNeedCertProfEng',
          }"
        >
          <div class="inner-dotted-border">
            <div
              class="mb-0 position-relative"
              fxLayout="row wrap"
              fxLayoutAlign="space-between start"
            >
              <div style="width: 45%; text-align: start">
                <span
                  class="certify align-items-center certify d-flex font-bold"
                >
                  <span class="header-info">التاريخ : </span>&nbsp;
                  <span style="font-size: 22px" class="header-info">{{
                    printCertificatData?.issueDate
                  }}</span>
                </span>
              </div>
            </div>
            <span class="certification"> " لمن يهمه الامر "</span>
            <br /><br />
            <div class="mt-2" fxLayout="row wrap">
              <div fxFlex="100" class="d-flex justify-content-start">
                <div class="certify d-flex flex-column w-100">
                  <span class="mb-4"
                    >يشهد مجلس تأهيل وتصنيف المهندسين - جمعية المهندسين الكويتيه
                    بأن :-&nbsp;</span
                  >
                  <div
                    class="name font-bold d-flex justify-content-center mb-2 mt-2"
                  >
                    <span> المهندس /</span>
                    <span
                      >&nbsp;{{ printCertificatData?.arabicName }}&nbsp;</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="" fxLayout="column wrap" fxLayoutAlign="start center">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%; margin-bottom: 170px"
                class="d-flex flex-column w-100 align-items-start"
              >
                <div style="width: fit-content">
                  <div class="certify gaped d-flex flex-column">
                    <div
                      class="align-items-center d-flex justify-content-start flex-wrap"
                      style="line-height: 1.5"
                    >
                      <span style="white-space: nowrap"
                        >قد تقدم لإختبار المهندس المحترف في تخصص</span
                      >
                      <span style="white-space: nowrap"
                        >"&nbsp;{{
                          printCertificatData?.profession
                        }}&nbsp;"</span
                      >
                    </div>
                    <div class="align-items-center d-flex">
                      <span>&nbsp;يوم&nbsp;</span>
                      <span>{{ printCertificatData?.testDay }}&nbsp;"</span>
                      <span>الموافق</span>
                      <span
                        >&nbsp;{{ printCertificatData?.panelDate }}&nbsp;</span
                      >
                      <span>وقد حصل في الإختبار علي نسبة</span>
                      <span
                        >&nbsp;({{ printCertificatData?.degree_percentage }} %).
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-start w-100">
                <div
                  class="align-items-end d-flex justify-content-around w-100"
                >
                  <span style="font-size: 22px"
                    >مجلس تأهيل وتصنيف المهندسين</span
                  >
                  <div style="width: 80px">
                    <qrcode
                      id="qr-3"
                      [elementType]="qr_elementType"
                      [qrdata]="qrdata"
                      [scale]="scale"
                      [width]="qr_width"
                    ></qrcode>
                  </div>
                  <span
                    class="fs-30 font-bold signature"
                    style="font-size: 23px"
                    >جمعية المهندسين الكويتية</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      printCertificatData?.kseIssuedCertType?.actionCode ===
      'ToWhomeNeedCertSuperEng'
    "
    id="ToWhomeNeedCertSuperEng"
    class="px-4"
  >
    <div
      class="print-content"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      style="width: 100%; height: 100%"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          class="outer-border w-100"
          [ngClass]="{
            'large-padding':
              printCertificatData.kseIssuedCertType.actionCode ===
              'ToWhomeNeedCertProfEng',
          }"
        >
          <div class="inner-dotted-border">
            <div
              class="mb-0 position-relative"
              fxLayout="row wrap"
              fxLayoutAlign="space-between start"
            >
              <div style="width: 45%; text-align: start; margin-bottom: 24px">
                <span
                  class="certify align-items-center certify d-flex font-bold"
                >
                  <span
                    class="header-info"
                    style="font-weight: 400; font-size: 20px"
                    >التاريخ : </span
                  >&nbsp;
                  <span
                    style="font-size: 22px; font-weight: 400"
                    class="header-info"
                    >{{ printCertificatData?.issueDate }}</span
                  >
                </span>
              </div>
            </div>
            <div class="d-flex flex-column">
              <span class="certification m-0">" لمن يهمه الامر"</span>
              <span class="certification m-0">***</span>
            </div>
            <div class="mt-2" fxLayout="row wrap">
              <div fxFlex="100" class="d-flex justify-content-start">
                <div class="certify d-flex flex-column w-100">
                  <span class="mb-3">
                    تشهد جمعية المهندسين الكويتية - لجنة تقييم مهندسي الإشراف
                    بأن :-&nbsp;</span
                  >
                  <div class="d-flex flex-column">
                    <div
                      class="name font-bold d-flex justify-content-center mb-3 mt-2"
                    >
                      <span> المهندس /</span>
                      <span
                        >&nbsp;{{ printCertificatData?.arabicName }}&nbsp;</span
                      >
                    </div>
                    <div class="d-flex flex-column gap-3">
                      <span class="mb-2">عضوية جمعية المهندسين الكويتية </span>
                      <span class="mb-2"
                        >({{
                          printCertificatData?.personMembership.membershipNum
                        }})
                      </span>
                      <span class="font-bold"
                        >({{ printCertificatData?.engOfficeAr }})</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" fxLayout="column wrap" fxLayoutAlign="start center">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%"
                class="d-flex flex-column w-100 align-items-start"
              >
                <div style="width: fit-content">
                  <div class="certify gaped d-flex flex-column">
                    <div
                      class="align-items-center d-flex justify-content-start w-100"
                    >
                      <span>قد اجتاز مقابلة التقييم كمهندس إشراف في يوم</span>
                      <span
                        >&nbsp;{{ printCertificatData?.testDay }}&nbsp;</span
                      >
                      <span>الموافق</span>
                      <span
                        >&nbsp;{{ printCertificatData?.panelDate }}&nbsp;</span
                      >
                    </div>
                    <div
                      class="align-items-center d-flex flex-column gap-3 my-0"
                    >
                      <span class="d-flex w-100"
                        >علما بأن هذه الشهادة صالحة لمدة ثلاثة أشهر من تاريخ
                        الإصدار.</span
                      >
                      <span class="d-flex w-100 text-right"
                        >وقد اعطيت له هذه الشهادة بناءًا على طلبه دون ادنى
                        مسئولية على الجمعية تجاه الغير .</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-start w-100">
                <div
                  class="align-items-start d-flex justify-content-around w-100"
                >
                  <div class="d-flex flex-column gap-1" style="width: 23%">
                    <span class="font-bold mb-1" style="font-size: 22px"
                      >لجنة المقابلة</span
                    >
                    <span
                      class="font-bold"
                      style="font-size: 17px !important"
                      *ngFor="
                        let item of printCertificatData.facultyMembersPanelsName
                      "
                      >{{ item.name }}</span
                    >
                  </div>
                  <div style="width: 90px; height: 135px">
                    <qrcode
                      id="qr-4"
                      [elementType]="qr_elementType"
                      [qrdata]="qrdata"
                      [scale]="scale"
                      [width]="qr_width"
                    ></qrcode>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <img
                      src="../../../../assets/img/logos/LastLog.png"
                      style="width: 250px"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-start flex-column w-100">
                <div
                  class="d-flex align-items-center mb-3"
                  style="height: 18px"
                >
                  <span>اسم اللجنة:</span>
                  <span>{{ printCertificatData?.panel?.id }}</span>
                </div>
                <div class="d-flex align-items-center gap-5">
                  <div class="d-flex align-items-center" style="height: 18px">
                    <span>رقم الإيصال:</span>
                    <span>{{ printCertificatData?.receiptNum }}</span>
                  </div>
                  <div class="d-flex align-items-center" style="height: 18px">
                    <span>تاريخ الإيصال:</span>
                    <span>{{ printCertificatData?.receiptDate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" style="padding: 0.5rem 2rem">
    <button
      (click)="print()"
      printSectionId="{{ print_id }}"
      ngxPrint
      mat-button
      mat-raised-button
      [useExistingCss]="true"
      color="primary"
      class="mat-focus-indicator mat-primary print-button"
      style=""
    >
      {{ 'PrintCertificate' | translate }}
    </button>
  </div>
</div>
<ng-template #loader>
  <div class="col-sm-12 justify-content-center align-items-center d-flex">
    <!--    <div class="spinner-border loader">-->
    <!--      <span class="loading__anim" id=""></span>-->
    <!--    </div>-->
    <div class="dotted-loader"></div>
  </div>
</ng-template>
