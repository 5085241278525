import {
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ConfigService } from '../@config/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@config/services/navigation.service';
import { DropDownService } from 'src/@config/services/dropdown.service';
import { LayoutService } from '../@config/services/layout.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@config/services/splash-screen.service';
import { AppConfigName } from '../@config/config/config-name.model';
import { ColorSchemeName } from '../@config/config/colorSchemeName';
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { secureStorage } from './shared/functions/secure-storage';
import { AuthService } from './services/auth.service';
import { SideNavItems } from '../@config/sidenav/sidenav-items';
import {
  getCurrentUserRoles,
  setMomentLocal,
} from './shared/functions/shared-functions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PageTitleService } from './core/components/page-title/page-title.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoreService } from './services/core.service';
import { GlobalService } from './services/global.service';
import { BreadcrumbsService } from '@exalif/ngx-breadcrumbs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  selectedImage;
  // some fields to store our state so we can display it in the UI
  idleState = 'NOT_STARTED';
  countdown?: number = null;
  lastPing?: Date = null;
  timeout = false;

  constructor(
    private translate: TranslateService,
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private pageTitleService: PageTitleService,
    private navigationService: NavigationService,
    private dropDownService: DropDownService,
    private splashScreenService: SplashScreenService,
    private authService: AuthService,
    private sideNavItems: SideNavItems,
    public globalService: GlobalService,
    private breadcrumbsService: BreadcrumbsService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private idle: Idle,
    private keepalive: Keepalive,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
  ) {
    // set idle parameters
    idle.setIdle(environment.idle_time_in_seconds); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(environment.time_before_logout_in_seconds); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active, things like clicks, scrolls, touches to the document

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
      if (this.authService.isLoggedIn === true) {
        this.toastr.warning(
          this.translate.instant('Logged out'),
          this.translate.instant('Session ended'),
          {
            timeOut: 600000000,
          },
        );
      }
      // console.log(this.idleState, this.lastPing);
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      // console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      // this.timeout = false;
      cd.detectChanges(); // how do i avoid this kludge?
      // console.log(this.idleState, this.lastPing);
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      if (this.authService.isLoggedIn === true) {
        this.timeout = true;
        this.authService.logOut();
        this.reset();
      }
      // console.log(this.idleState, this.lastPing);
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe((seconds) => (this.countdown = seconds));

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(10); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      if (this.timeout) {
        setTimeout(() => {
          this.toastr.clear();
          console.log('1111');
        }, 500);
      }
    }); // do something when it pings
  }

  ngOnInit() {
    // right when the component initializes, start reset state and start watching
    this.reset();
    this.subscribeToImageViewer();
    this.setTitle();
    setMomentLocal();
    Settings.defaultLocale = this.localeId;
    // GetDeviceInfoFromIPAddress();
    // this.globalService.getMyCountryByIp();
    // this.getCountry();
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
    this.authService.isAuthorized() &&
      this.configService.updateConfig({
        toolbar: {
          user: {
            visible: true,
          },
        },
      });
    this.registerIcons();

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(
          queryParamMap.get('layout') as AppConfigName,
        );
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName,
          },
        });
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl'))
            ? 'rtl'
            : 'ltr',
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = this.sideNavItems.getAll();
    this.setLocalization();
    // this.getQuickList();
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = null;
    this.lastPing = null;
  }

  getQuickList() {
    this.coreService
      .getRequest(`admin/quick-list`)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.configService.quickList$.next(res?.data);
      });
  }

  subscribeToImageViewer() {
    this.globalService.imageViewerObservable$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.selectedImage = res;
      });
  }

  getCountry() {
    this.coreService
      .getRequest('get-user-country-info')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        secureStorage.setItem('location', {
          country: res?.country?.replace(' ', ''),
        });
      });
  }

  registerIcons() {
    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string,
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`,
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`,
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`,
            );
        }
      },
    );
  }

  setLocalization() {
    this.translate.addLangs(['en', 'ar']);
    this.translate.setDefaultLang('ar');
    const lang = secureStorage.getItem('lang') || 'ar';
    secureStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.configService.updateConfig({
      direction: lang == 'en' ? 'ltr' : 'rtl',
    });
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.body.style.direction = dir;
    // (window as any).primaryColor = '#337ab7';
    // (window as any).headerColor = '#2b3643';
    // (window as any).footerColor = '#364150';
    // (window as any).thirdColor = '#b1dff5';
    // (window as any).greyColor = '#929391';
    // document.documentElement.style.setProperty('--primary-color', (window as any).primaryColor);
    // document.documentElement.style.setProperty('--header-color', (window as any).headerColor);
    // document.documentElement.style.setProperty('--footer-color', (window as any).footerColor);
    // document.documentElement.style.setProperty('--thirdColor', (window as any).thirdColor);
    // document.documentElement.style.setProperty('--grey-color', (window as any).greyColor);
  }

  setTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => {
          return route.outlet === 'primary';
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        event['title'] ? this.pageTitleService.setTitle(event['title']) : null;
      });
  }
}
