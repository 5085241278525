import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { debounceTime, filter, Observable } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CoreService } from '../../../services/core.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AutoComplete } from '../../auto-complete';
import {
  FilterRes,
  GetLanguage,
  HandleResponseError,
  getCurrentUserRoles,
  getDropdownSettings,
} from '../../functions/shared-functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';
import { secureStorage } from '../../functions/secure-storage';
import { DropDownService } from 'src/@config/services/dropdown.service';
import moment, { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { GlobalService } from 'src/app/services/global.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown3';
import {
  ACCOUNT_TYPE_LIST,
  ACTION_CODE_LIST,
  ARABIC_ENGLISH_NAME_LIST,
  CASHIER_USER_LIST,
  CATEGORY_NAME_LIST,
  CIVIL_ID_LIST,
  CLASSIFICATION_TYPE_LIST,
  COMPANY_FILE_NAME_LIST,
  COMPANY_FILE_NUM_LIST,
  COMPANY_NAME_LIST,
  DATE_LIST,
  EMAIL_LIST,
  EVALUATION_DATE_LIST,
  EVALUATION_STATUS_LIST,
  EVALUATION_TYPE_LIST,
  FILE_NUM_LIST,
  FORM_DATE_LIST,
  FROM_TO_DATE_REQUIRED_LIST,
  IS_MEMBERSHIP_LIST,
  JOB_LIST,
  KUWAIT_PHONE_FULL_NAME_LIST,
  MEMBERSHIP_NUM_LIST,
  NAME_LIST,
  NATIONALITY_LIST,
  NOC_LIST,
  PASSPORT_LIST,
  PAYMENT_METHOD_LIST,
  PHONE_LIST,
  PROFESSION_LIST,
  PageNumber,
  RECEIPT_NUM_LIST,
  RESIDENCY_TYPE_LIST,
  REVIEW_DATE_LIST,
  SERVICE_LIST,
  STATUS_TYPE_LIST,
  TO_DATE_LIST,
  USERNAME_LIST,
  USER_LIST,
} from '../../variables/variables';

@UntilDestroy()
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent extends AutoComplete implements OnInit, OnChanges {
  @Output() filter = new EventEmitter<any>();
  @Input() listName: any;
  @Input() valuesGoingToFilter: any;
  @Input() reset: any = true;
  @Input() monthFilter = [];
  @Output() monthSelected = new EventEmitter<any>();
  form: UntypedFormGroup;
  apiUrl = environment.webUrl;
  accountTypesFilteredOptions: Observable<any[]>;
  accountTypes = [];
  auditCategoryMethodsFilteredOptions: Observable<any[]>;
  auditCategoryMethods = [];
  auditCategoryFilteredOptions: Observable<any[]>;
  auditCategory = [];
  evaluationTypes = [];
  evaluationStatus = [];
  chatServices = [];
  panels = [];
  jobs = [];
  residencies = [];
  facultyMembers = [];
  showCases = [];
  countries = [];
  colleges = [];
  ratings = [];
  professions = [];
  subProfessions = [];
  eduTypes = [];
  universities = [];
  companies = [];
  nationalities = [];
  statusTypes = [];
  paymentMethods = [];
  classificationTypes = [];
  services = [];
  cashierUsers = [];
  evalStatus = [];
  personData = [];
  cashierOperations = [];
  classification_card_types = [];
  classification_cert_types = [];
  stampTypes = [];
  trying_times = [];

  eduTypesFilteredOptions: Observable<any[]>;
  ratingsFilteredOptions: Observable<any[]>;
  countriesFilteredOptions: Observable<any[]>;
  universitiesFilteredOptions: Observable<any[]>;
  collegesFilteredOptions: Observable<any[]>;
  professionsFilteredOptions: Observable<any[]>;
  subProfessionsFilteredOptions: Observable<any[]>;
  jobsFilteredOptions: Observable<any[]>;
  gendersFilteredOptions: Observable<any[]>;
  companiesFilteredOptions: Observable<any[]>;
  statusTypesFilteredOptions: Observable<any[]>;
  nationalitiesFilteredOptions: Observable<any[]>;
  residenciesFilteredOptions: Observable<any[]>;
  paymentMethodsFilteredOptions: Observable<any[]>;
  evalTypeFilteredOptions: Observable<any[]>;
  classificationTypesOptions: Observable<any[]>;
  trying_timesFilterOptions: Observable<any[]>;
  evalStatusFilteredOptions: Observable<any[]>;
  personDataFilteredOptions: Observable<any[]>;
  servicesFilteredOptions: Observable<any[]>;
  cashierUsersFilteredOptions: Observable<any[]>;
  classificationCardTypesFilteredOptions: Observable<any[]>;
  evaluStatusFilteredOptions: Observable<any[]>;
  cashierOperationsFilteredOptions: Observable<any[]>;
  stampTypesFilteredOptions: Observable<any[]>;
  classificationCertTypesFilteredOptions: Observable<any[]>;
  today = new Date();
  minDateTo = new Date();
  roles = getCurrentUserRoles();
  caseRoles = [
    'BoShowNewReviewAndNotPathEval',
    'BoShowFianlReview',
    'BoShowReviewNotPathInvestCompany',
  ];
  caseOptions = [
    { name: 'New Audit / Not Passed Assessment', value: 1 },
    { name: 'The final stage of auditing', value: 2 },
    { name: '(Awaited / Rejected) Verification company', value: 3 },
  ];
  genders: any[] = [
    {
      name: this.translateService.instant('Male'),
      id: 1,
    },
    {
      name: this.translateService.instant('Female'),
      id: 2,
    },
  ];
  yesOrNo: any[] = [
    {
      name: this.translateService.instant('Yes'),
      id: true,
    },
    {
      name: this.translateService.instant('No'),
      id: false,
    },
  ];
  nocOptions: any[] = [
    {
      name: this.translateService.instant('Transferred'),
      id: 'true',
    },
    {
      name: this.translateService.instant('Not Transferred'),
      id: 'false',
    },
  ];
  isCompanyUser =
    getCurrentUserRoles()?.includes('BoShowReceptionReviewCompany') &&
    !getCurrentUserRoles()?.includes('BoShowReceptionReviewPerson');
  reportFiltersLoaded = false;
  allCashierApi = 'User/GetAllUsers?AccountTypeCodeString=BOAccount';
  initialCashierOperation;
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  companiesScroll = new UntypedFormControl();
  search_word = '';
  companiesFilter = new UntypedFormControl();
  pageNumber = PageNumber;
  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private router: Router,
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private dropDownService: DropDownService,
    private globalService: GlobalService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.reset && this.form) {
      // this.resetForm();
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((res) => {
        this.globalService.filterDataChangedSubject.next(undefined);
        this.form.reset();
      });

    this.form = this.fb.group(
      {
        account_type: new UntypedFormControl('', []),
        username: new UntypedFormControl('', []),
        panel_name: new UntypedFormControl('', []),
        email: new UntypedFormControl('', []),
        civil_ID: new UntypedFormControl('', []),
        phone_number: new UntypedFormControl('', []),
        passport: new UntypedFormControl('', []),
        review_date: new UntypedFormControl('', []),
        appointment_date: new UntypedFormControl('', []),
        date_from: new UntypedFormControl('', []),
        date_to: new UntypedFormControl('', []),
        date_from_required: new UntypedFormControl(
          new Date(this.globalService.getPreviousMonth()),
          [],
        ),
        date_to_required: new UntypedFormControl(
          new Date(this.globalService.getPreviousDay()),
          [],
        ),
        file_num: new UntypedFormControl('', []),
        receipt_Num: new UntypedFormControl('', []),
        name: new UntypedFormControl('', []),
        service: new UntypedFormControl('', []),
        date: new UntypedFormControl('', []),
        membership_num: new UntypedFormControl('', []),
        arabic_name: new UntypedFormControl('', []),
        english_name: new UntypedFormControl('', []),
        payment_method: new UntypedFormControl('', []),
        user: new UntypedFormControl('', []),
        action_code: new UntypedFormControl('', []),
        company_file_num: new UntypedFormControl('', []),
        kuwit_phone_num: new UntypedFormControl('', []),
        first_name: new UntypedFormControl('', []),
        second_name: new UntypedFormControl('', []),
        third_name: new UntypedFormControl('', []),
        family_name: new UntypedFormControl('', []),
        classification_type: new UntypedFormControl('', []),
        evaluation_type: new UntypedFormControl('', []),
        evaluation_date: new UntypedFormControl('', []),
        profession: new UntypedFormControl('', []),
        isMembership: new UntypedFormControl('', []),
        noc: new UntypedFormControl('', []),
        cashier_user: new UntypedFormControl('', []),
        category_name: new UntypedFormControl('', []),
        nationality: new UntypedFormControl('', []),
        job: new UntypedFormControl('', []),
        evaluation_status: new UntypedFormControl('', []),
        residency: new UntypedFormControl('', []),
        status_type: new UntypedFormControl('', []),
        month_filter: new UntypedFormControl('', []),
        company_file_number: new UntypedFormControl('', []),
        company_name: new UntypedFormControl('', []),
        final_review_fileno: new UntypedFormControl('', []),
        final_review_name: new UntypedFormControl('', []),
        evalResult: new UntypedFormControl('', []),
        panel_examiners_name: new UntypedFormControl('', []),
        add_evaluation_results_evaluation_type: new UntypedFormControl('', []),
        results_fileno: new UntypedFormControl('', []),
        final_review_status: new UntypedFormControl('', []),
        PersoncivilID: new UntypedFormControl('', []),
        PersonpassportNum: new UntypedFormControl('', []),
        passportNum: new UntypedFormControl('', []),
        civilID: new UntypedFormControl('', []),
        auditData_datetime_from: new UntypedFormControl('', []),
        auditData_datetime_to: new UntypedFormControl('', []),
        auditData_user_id: new UntypedFormControl('', []),
        auditData_data_id: new UntypedFormControl('', []),
        auditData_category: new UntypedFormControl('', []),
        auditData_category_method: new UntypedFormControl('', []),
        notification_data_title_ar: new UntypedFormControl('', []),
        notification_data_title_en: new UntypedFormControl('', []),
        add_message_chat_service: new UntypedFormControl('', []),
        basic_data_education_type: new UntypedFormControl('', []),
        basic_data_rate: new UntypedFormControl('', []),
        basic_data_country: new UntypedFormControl('', []),
        basic_data_univesity: new UntypedFormControl('', []),
        basic_data_colleg: new UntypedFormControl('', []),
        basic_data_prof: new UntypedFormControl('', []),
        basic_data_sub_prof: new UntypedFormControl('', []),
        basic_data_job: new UntypedFormControl('', []),
        basic_data_gender: new UntypedFormControl('', []),
        basic_data_company: new UntypedFormControl('', []),
        basic_data_status: new UntypedFormControl('', []),
        basic_data_nationality: new UntypedFormControl('', []),
        basic_data_residency: new UntypedFormControl('', []),
        reception_cashier_monthly_report_from: new UntypedFormControl(
          new Date(this.globalService.getPreviousMonth()),
          [],
        ),
        reception_cashier_monthly_report_to: new UntypedFormControl(
          new Date(this.globalService.getPreviousDay()),
          [],
        ),
        data_statistics_by_country_job: new UntypedFormControl('', []),
        data_statistics_by_country_gender: new UntypedFormControl('', []),
        data_statistics_by_country_company: new UntypedFormControl('', []),
        data_statistics_by_country_status: new UntypedFormControl('', []),
        data_statistics_by_country_nationality: new UntypedFormControl('', []),
        data_statistics_by_country_residency: new UntypedFormControl('', []),
        classification_status: new UntypedFormControl('', []),
        stamps_type: new UntypedFormControl('', []),
        trying_times: new UntypedFormControl('', []),
        form_reset: new UntypedFormControl('', []),
      },
      { validators: [this.checkAutoComplete()] },
    );
    if (this.listName === 'users' || this.listName === 'logoutUsers') {
      this.getAllAccountTypes();
    }
    if (
      this.listName === 'evaluation' ||
      this.listName === 'evaluationSuccessAndFailureReport'
    ) {
      this.getEvaluationTypes();
      this.getJobs();
    }
    if (this.listName === 'chatMessages') {
      this.getChatServices();
    }
    if (this.listName === 'results') {
      this.getFacultyMembers();
    }
    if (
      this.listName === 'final-reviews' ||
      this.listName === 'final-audition'
    ) {
      this.getResidencies();
      this.checkCasesRoles();
    }
    if (this.listName === 'auditData') {
      this.getAuditCategoryMethods();
      this.getAuditCategory();
    }

    if (this.listName == 'basicDataReport') {
      this.getStatusType('basic_data_status');
      this.getEducationType('basic_data_education_type');
      //this.getAllCompany('basic_data_company');
      this.getAllNationality('basic_data_nationality');
      this.getJobs();
      this.subscribeToObservable();
      this.getAllCompanies();
      this.subscribeToGetScroll();
      this.subscribeToGetCompanies();
    }

    if (this.listName == 'dailyAccountingReport') {
      this.getPaymentMethods();
    }
    if (this.listName == 'evaluationDetailsReport') {
      this.getEvaluationStatus('evaluation_status');
      this.getEvaluationType('evaluation_type');
      this.getAllNationality('nationality');
      this.getJobs();
      this.subscribeToObservable();
    }
    if (this.listName == 'financialReportByCashierReport') {
      this.getService('service');
      this.getBOAccounts('cashier_user');
    }
    if (this.listName == 'accServiceRequest') {
      this.getService('service');
    }
    if (this.listName == 'financialReportByReceptionCashierReport') {
      this.getService('service');
      this.getBOAccounts('cashier_user');
    }
    if (this.listName == 'financialReportByServiceCashierReport') {
      this.getService('service');
      this.getBOAccounts('cashier_user');
    }
    if (this.listName == 'financialReportByServiceReceptionCashierReport') {
      this.getService('service');
      this.getBOAccounts('cashier_user');
    }
    if (this.listName == 'receptionCashierReport') {
      this.getService('service');
      this.getAllCashierUsers('cashier_user');
      this.subscribeToObservable();
    }
    if (this.listName == 'receptionCashierMonthlyReport') {
      this.getService('service');
      this.getAllCashierUsers('cashier_user');
      this.subscribeToObservable();
    }
    if (this.listName == 'societyCashierReport') {
      this.getService('service');
      this.getAllCashierUsers('cashier_user');
      this.subscribeToObservable();
    }
    if (this.listName == 'societyCashierMonthlyReport') {
      this.getService('service');
      this.getAllCashierUsers('cashier_user');
      this.subscribeToObservable();
    }
    if (this.listName == 'dataStatisticsByCountryReport') {
      this.getStatusType('data_statistics_by_country_status');
      this.getAllCompany('data_statistics_by_country_company');
      this.getAllNationality('data_statistics_by_country_nationality');
      this.getJobs();
      this.subscribeToObservable();
    }
    if (
      this.listName == 'successAndFailedStatisticsReport' ||
      this.listName == 'classification_results' ||
      this.listName == 'evalution_results'
    ) {
      this.getEvaluationType('evaluation_type');
      this.getEvaluationStatus('evaluation_status');
    }
    if (this.listName == 'evaluationByNationalityReport') {
      this.getEvaluationStatus('evaluation_status');
      this.getAllNationality('nationality');
      this.getEvaluationType('evaluation_type');
      this.getJobs();
      this.subscribeToObservable();
    }
    if (this.listName == 'evaluationByJobReport') {
      this.getEvaluationStatus('evaluation_status');
      this.getAllNationality('nationality');
      this.getEvaluationType('evaluation_type');
      this.getJobs();
      this.subscribeToObservable();
    }
    if (this.listName == 'personStatusByUserReport') {
      this.getStatusType('status_type');
      this.getPersonData();
      this.subscribeToObservable();
    }
    if (this.listName == 'serviceRequestByUserReport') {
      this.getStatusType('status_type');
      this.getPersonData();
      this.subscribeToObservable();
    }
    if (this.listName == 'personOperationsReport') {
      this.getCashierOperations();
      this.subscribeToObservable();
      this.getAllCashierUsers('cashier_user');
    }
    if (this.listName == 'classification_panels') {
      this.getClassificationType();
      this.getEvaluationType('evaluation_type');
    }
    if (this.listName == 'evalution_panels') {
      this.getEvaluationType('evaluation_type');
    }
    if (this.listName == 'classificationCards') {
      this.getClassificationCardsType();
    }
    if (this.listName === 'finacialTransaction') {
      this.getPersonData();
      this.getService('service');
      this.getPaymentMethods();
    }

    if (
      this.listName == 'classificationCertificates' ||
      this.listName === 'classificationCards'
    ) {
      this.getClassificationCertType();
    }
    if (this.listName == 'classificationStamp') {
      this.getKseIssuedStampType();
    }
    if (
      this.listName == 'classificationSuccessAndFailureReport' ||
      this.listName == 'classification_results' ||
      this.listName == 'evaluationSuccessAndFailureReport'
    ) {
      this.getClassificationType();
      this.classificationTryingTimes();
    }

    // this.resetForm();
    this.form.valueChanges.pipe(debounceTime(3000)).subscribe((res) => {
      // if (this.form.status === 'VALID') {
      //   this.filter.emit(res);
      // }
    });
    if (this.valuesGoingToFilter) {
      this.form.patchValue(this.valuesGoingToFilter);
      this.apply();
    }
  }

  getClassificationCardsType() {
    this.coreService.getRequest('kseissuedcardtype').subscribe(
      (res) => {
        this.classification_card_types = FilterRes(res);
        this.classification_card_types.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getClassificationCertType() {
    this.coreService.getRequest('kseIssuedCertType').subscribe(
      (res) => {
        this.classification_cert_types = FilterRes(res);
        this.classification_cert_types.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getKseIssuedStampType() {
    this.coreService.getRequest('KseIssuedStampType').subscribe(
      (res) => {
        this.stampTypes = FilterRes(res);
        this.stampTypes.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.handlerAutocomplete('stamps_type');
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getClassificationType() {
    this.coreService.getRequest('ClassificationType').subscribe(
      (res) => {
        this.classificationTypes = FilterRes(res);
        this.classificationTypes.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.handlerAutocomplete('classificationTypes');
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getPaymentMethods() {
    this.coreService.getRequest('PaymentMethod').subscribe(
      (res) => {
        this.paymentMethods = FilterRes(res);
        this.paymentMethods.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.handlerAutocomplete('payment_method');
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getService(autoCompleteList) {
    this.coreService.getRequest('Service').subscribe((res) => {
      this.services = FilterRes(res);
      this.services.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
      );
      this.handlerAutocomplete(autoCompleteList);
    }),
      (err) => {
        this.toastr.error(HandleResponseError(err));
      };
  }

  getStatusType(autoCompleteList) {
    this.coreService.getRequest('StatusType').subscribe(
      (res) => {
        this.statusTypes = FilterRes(res);
        this.statusTypes.map(
          (item) =>
            (item.name =
              GetLanguage() === 'ar' ? item.nameArabic : item.nameEng),
        );
        this.handlerAutocomplete(autoCompleteList);
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getEvaluationType(autoCompleteList) {
    this.coreService.getRequest('EvaluationType').subscribe(
      (res) => {
        this.evaluationTypes = FilterRes(res);
        this.evaluationTypes.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.handlerAutocomplete(autoCompleteList);
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getEvaluationStatus(autoCompleteList) {
    this.coreService.getRequest('EvaluationStatus').subscribe((res) => {
      this.evaluationStatus = FilterRes(res);
      this.evaluationStatus.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
      );
      this.handlerAutocomplete(autoCompleteList);
    }),
      (err) => {
        this.toastr.error(HandleResponseError(err));
      };
  }

  getEducationType(autoCompleteList) {
    this.coreService
      .getRequest('CertificateType/GetAllCertificateType')
      .subscribe(
        (res) => {
          this.eduTypes = FilterRes(res);
          this.eduTypes.map(
            (item) =>
              (item.name =
                GetLanguage() === 'ar'
                  ? item.certTypeNameAr
                  : item.certTypeNameEn),
          );
          this.handlerAutocomplete(autoCompleteList);
        },
        (err) => {
          this.toastr.error(HandleResponseError(err));
        },
      );
  }

  getAllCompany(autoCompleteList) {
    this.coreService.getRequest('company').subscribe(
      (res) => {
        this.companies = FilterRes(res);
        this.companies.map(
          (item) =>
            (item.name =
              GetLanguage() === 'ar' ? item.nAmeArabic : item.nameEng),
        );
        this.handlerAutocomplete(autoCompleteList);
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getAllNationality(autoCompleteList) {
    this.coreService.getRequest('Nationality').subscribe(
      (res) => {
        this.nationalities = FilterRes(res);
        this.nationalities.map(
          (item) =>
            (item.name =
              GetLanguage() === 'ar' ? item.nameArabic : item.nameEng),
        );
        this.handlerAutocomplete(autoCompleteList);
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  getBOAccounts(autoCompleteList) {
    this.coreService.getRequest('User/GetAllUsers?AccountTypeCode=4').subscribe(
      (res) => {
        this.cashierUsers = FilterRes(res);
        this.cashierUsers.map(
          (item) => (item.name = `${item?.firstName} ${item?.familyName}`),
        );
        this.handlerAutocomplete(autoCompleteList);
      },
      (err) => {
        this.toastr.error(HandleResponseError(err));
      },
    );
  }

  subscribeToObservable() {
    this.dropDownService.dropdowns_dataObservable$
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          if (res) {
            this.countries = res['countries'];
            this.ratings = res['eduRatings'];
            this.professions = res['professions'];
            this.subProfessions = res['subProfessions'];
            this.jobs = res['jobs'];
            this.residencies = res['residencies'];

            this.handlerAutocomplete('basic_data_rate');
            this.handlerAutocomplete('basic_data_country');
            this.handlerAutocomplete('basic_data_prof');
            this.handlerAutocomplete('basic_data_sub_prof');
            this.handlerAutocomplete('basic_data_job');
            this.handlerAutocomplete('basic_data_gender');
            this.handlerAutocomplete('basic_data_residency');
            this.handlerAutocomplete('job');
            this.handlerAutocomplete('cashier_user');
            this.handlerAutocomplete('data_statistics_by_country_job');
            this.handlerAutocomplete('data_statistics_by_country_gender');
            this.handlerAutocomplete('data_statistics_by_country_residency');
            this.handlerAutocomplete('residency');
            this.handlerAutocomplete('user');

            this.reportFiltersLoaded = true;
          }
        },
        (error) => {
          this.reportFiltersLoaded = false;
          this.toastr.error(HandleResponseError(error));
        },
        () => {},
      );
  }

  classificationTryingTimes() {
    const options = [
      'Successful from the first time',
      'Successful from the second time',
      'Successful from the third time',
      'Successful from the fourth time and more',
      'Failed',
    ];
    this.translateService.get(options).subscribe((res) => {
      this.trying_times = [
        {
          name: res['Successful from the first time'],
          id: 1,
        },
        {
          name: res['Successful from the second time'],
          id: 2,
        },
        {
          name: res['Successful from the third time'],
          id: 3,
        },
        {
          name: res['Successful from the fourth time and more'],
          id: 4,
        },
        {
          name: res['Failed'],
          id: 0,
        },
      ];
      this.handlerAutocomplete('trying_times');
    });
  }

  getEvalStatus() {
    const options = [
      'Successful from the first time',
      'Successful from the second time',
      'Successful from the third time',
      'Successful from the fourth time and more',
    ];
    this.translateService.get(options).subscribe((res) => {
      this.evalStatus = [
        {
          name: res['Successful from the first time'],
          id: 1,
        },
        {
          name: res['Successful from the second time'],
          id: 2,
        },
        {
          name: res['Successful from the third time'],
          id: 3,
        },
        {
          name: res['Successful from the fourth time and more'],
          id: 4,
        },
      ];
      this.handlerAutocomplete('evaluation_status');
    });
  }

  getCashierOperations() {
    this.translateService
      .get(['Cashier operations', 'Files operations'])
      .subscribe((res) => {
        this.cashierOperations = [
          {
            name: res['Cashier operations'],
            id: 'Cashier',
          },
          {
            name: res['Files operations'],
            id: 'Files',
          },
        ];
      });
    this.initialCashierOperation = this.cashierOperations.filter(
      (el) => el.id === 'Cashier',
    )[0];
    // this.handlerAutocomplete('person_operations_operation');
    this.form.patchValue({
      // person_operations_operation: this.initialCashierOperation
    });
  }

  resetForm() {
    this.form.reset();
    this.form.patchValue({
      reception_cashier_monthly_report_from: new Date(
        this.globalService.getPreviousMonth(),
      ),
      reception_cashier_monthly_report_to: new Date(
        this.globalService.getPreviousDay(),
      ),
      date_from_required: new Date(this.globalService.getPreviousMonth()),
      date_to_required: new Date(this.globalService.getPreviousDay()),
    });
    this.form.controls['form_reset']?.setValue(true);
    this.filter.emit(this.form.value);
  }

  checkCasesRoles() {
    // HANDLE ROLE FOR EVERY CASE (1, 2, 3)
    for (const i in this.caseRoles) {
      if (this.roles.includes(this.caseRoles[i])) {
        this.showCases.push(this.caseOptions[i]);
      }
    }
  }

  getResidencies() {
    this.coreService.getRequest('ResidencyType').subscribe((res) => {
      // console.log('res resd', res)
      res?.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameArabic : item.nameEng),
      );
      this.residencies = res;
    });
  }

  getAllAccountTypes() {
    this.coreService.getRequest('AccountType').subscribe((res) => {
      // console.log('res jobs', res)
      this.accountTypes = FilterRes(res);
      this.accountTypes.map((item) => {
        if (item.accountTypeCode === 'FacultyMemberAccount') {
          item.role = 'BoShowUserTypeFaMembership';
        }
        if (item.accountTypeCode === 'BOAccount') {
          item.role = 'BoShowUserTypeBO';
        }
        if (item.accountTypeCode === 'FOAccount') {
          item.role = 'BoShowUserTypeFO';
        }
        item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn;
      });
      this.handlerAutocomplete('account_type');
      // const intialAccountType = this.accountTypes.filter(el => el.id === 3)[0];
      // this.form.controls['account_type'].setValue(intialAccountType);
      // this.apply()
    });
  }

  getAuditCategoryMethods() {
    this.coreService.getRequest('AuditCategoryMethod').subscribe((res) => {
      this.auditCategoryMethods = FilterRes(res);
      this.auditCategoryMethods.map((item) => (item.name = item.describtion));
      this.handlerAutocomplete('auditData_category_method');
      //this.form.controls['auditData_category_method'].setValue(this.auditCategoryMethods[0]);
    }),
      (err) => {};
  }

  getAuditCategory() {
    this.coreService.getRequest('AuditCategory').subscribe(
      (res) => {
        this.auditCategory = FilterRes(res);
        this.auditCategory.map((item) => (item.name = item.categoryName));
        this.handlerAutocomplete('auditData_category');
        //this.form.controls['auditData_category'].setValue(this.auditCategory[0]);
      },
      (err) => {},
    );
  }

  handlerAutocomplete(type) {
    if (type === 'account_type') {
      this.accountTypesFilteredOptions = this.fillAutocomplete(
        this.form,
        'account_type',
        this.accountTypes,
      );
    } else if (type === 'auditData_category_method') {
      this.auditCategoryMethodsFilteredOptions = this.fillAutocomplete(
        this.form,
        'auditData_category_method',
        this.auditCategoryMethods,
      );
    } else if (type === 'auditData_category') {
      this.auditCategoryFilteredOptions = this.fillAutocomplete(
        this.form,
        'auditData_category',
        this.auditCategory,
      );
    } else if (type === 'basic_data_education_type') {
      this.eduTypesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_education_type',
        this.eduTypes,
      );
    } else if (type === 'basic_data_rate') {
      this.ratingsFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_rate',
        this.ratings,
      );
    } else if (type === 'basic_data_country') {
      this.countriesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_country',
        this.countries,
      );
    } else if (type === 'basic_data_univesity') {
      this.universitiesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_univesity',
        this.universities,
      );
    } else if (type === 'basic_data_colleg') {
      this.collegesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_colleg',
        this.colleges,
      );
    } else if (type === 'basic_data_prof') {
      this.professionsFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_prof',
        this.professions,
      );
    } else if (type === 'basic_data_sub_prof') {
      this.subProfessionsFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_sub_prof',
        this.subProfessions,
      );
    } else if (type === 'basic_data_job') {
      this.jobsFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_job',
        this.jobs,
      );
    } else if (type === 'basic_data_gender') {
      this.gendersFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_gender',
        this.genders,
      );
    } else if (type === 'basic_data_company') {
      this.companiesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_company',
        this.companies,
      );
    } else if (type === 'basic_data_status') {
      this.statusTypesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_status',
        this.statusTypes,
      );
    } else if (type === 'basic_data_nationality') {
      this.nationalitiesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_nationality',
        this.nationalities,
      );
    } else if (type === 'basic_data_residency') {
      this.residenciesFilteredOptions = this.fillAutocomplete(
        this.form,
        'basic_data_residency',
        this.residencies,
      );
    } else if (type === 'payment_method') {
      this.paymentMethodsFilteredOptions = this.fillAutocomplete(
        this.form,
        'payment_method',
        this.paymentMethods,
      );
    } else if (type === 'evaluation_status') {
      this.evalStatusFilteredOptions = this.fillAutocomplete(
        this.form,
        'evaluation_status',
        this.evaluationStatus,
      );
    } else if (type === 'service') {
      this.servicesFilteredOptions = this.fillAutocomplete(
        this.form,
        'service',
        this.services,
      );
    } else if (type === 'cashier_user') {
      this.cashierUsersFilteredOptions = this.fillAutocomplete(
        this.form,
        'cashier_user',
        this.cashierUsers,
      );
    } else if (type === 'data_statistics_by_country_job') {
      this.jobsFilteredOptions = this.fillAutocomplete(
        this.form,
        'data_statistics_by_country_job',
        this.jobs,
      );
    } else if (type === 'data_statistics_by_country_gender') {
      this.gendersFilteredOptions = this.fillAutocomplete(
        this.form,
        'data_statistics_by_country_gender',
        this.genders,
      );
    } else if (type === 'data_statistics_by_country_company') {
      this.companiesFilteredOptions = this.fillAutocomplete(
        this.form,
        'data_statistics_by_country_company',
        this.companies,
      );
    } else if (type === 'data_statistics_by_country_status') {
      this.statusTypesFilteredOptions = this.fillAutocomplete(
        this.form,
        'data_statistics_by_country_status',
        this.statusTypes,
      );
    } else if (type === 'data_statistics_by_country_nationality') {
      this.nationalitiesFilteredOptions = this.fillAutocomplete(
        this.form,
        'data_statistics_by_country_nationality',
        this.nationalities,
      );
    } else if (type === 'data_statistics_by_country_residency') {
      this.residenciesFilteredOptions = this.fillAutocomplete(
        this.form,
        'data_statistics_by_country_residency',
        this.residencies,
      );
    } else if (type === 'evaluation_status') {
      this.evaluStatusFilteredOptions = this.fillAutocomplete(
        this.form,
        'evaluation_status',
        this.evalStatus,
      );
    } else if (type === 'residency') {
      this.residenciesFilteredOptions = this.fillAutocomplete(
        this.form,
        'residency',
        this.residencies,
      );
    } else if (type === 'job') {
      this.jobsFilteredOptions = this.fillAutocomplete(
        this.form,
        'job',
        this.jobs,
      );
    } else if (type === 'status_type') {
      this.statusTypesFilteredOptions = this.fillAutocomplete(
        this.form,
        'status_type',
        this.statusTypes,
      );
    } else if (type === 'user') {
      this.personDataFilteredOptions = this.fillAutocomplete(
        this.form,
        'file_num',
        this.personData,
      );
    } else if (type === 'user') {
      this.personDataFilteredOptions = this.fillAutocomplete(
        this.form,
        'user',
        this.personData,
      );
    } else if (type === 'person_operations_operation') {
      // this.cashierOperationsFilteredOptions = this.fillAutocomplete(this.form, 'person_operations_operation', this.cashierOperations);
    } else if (type === 'classification_status') {
      this.evaluStatusFilteredOptions = this.fillAutocomplete(
        this.form,
        'classification_status',
        this.evalStatus,
      );
    } else if (type === 'classification_type') {
      this.evalTypeFilteredOptions = this.fillAutocomplete(
        this.form,
        'classification_type',
        this.evaluationTypes,
      );
    } else if (type === 'classificationTypes') {
      this.classificationTypesOptions = this.fillAutocomplete(
        this.form,
        'classification_type',
        this.classificationTypes,
      );
    } else if (type === 'evaluation_type') {
      this.evalTypeFilteredOptions = this.fillAutocomplete(
        this.form,
        'evaluation_type',
        this.evaluationTypes,
      );
    } else if (type === 'stamps_type') {
      this.stampTypesFilteredOptions = this.fillAutocomplete(
        this.form,
        'stamps_type',
        this.stampTypes,
      );
    } else if (type === 'trying_times') {
      this.trying_timesFilterOptions = this.fillAutocomplete(
        this.form,
        'trying_times',
        this.trying_times,
      );
    } else if (type === 'nationality') {
      this.nationalitiesFilteredOptions = this.fillAutocomplete(
        this.form,
        'nationality',
        this.nationalities,
      );
    }
  }

  checkAutoComplete() {
    return (formGroup: UntypedFormGroup) => {
      const account_type = formGroup?.controls['account_type'];
      //////////////////////////////////////////////////////////////
      const indexAccType =
        this.listName === 'users'
          ? this.accountTypes.findIndex(
              (res) => res?.id === account_type.value?.id,
            )
          : 0;
      this.checkWrongError(indexAccType, account_type);
    };
  }

  getEvaluationTypes() {
    this.coreService.getRequest('EvaluationType').subscribe((res) => {
      this.evaluationTypes = res;
      this.evaluationTypes = FilterRes(res);
      this.evaluationTypes.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
      );
      this.handlerAutocomplete('evaluation_type');
    });
  }

  getChatServices() {
    this.coreService.getRequest('Chat/getChatService').subscribe((res) => {
      this.chatServices = res?.body;
      this.chatServices = FilterRes(res);
      this.chatServices.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
      );
    });
  }

  getJobs() {
    this.coreService.getRequest('Jobs').subscribe((res) => {
      this.jobs = FilterRes(res);
      this.jobs.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
      );
      this.handlerAutocomplete('job');
    });
  }

  getPersonData() {
    this.coreService.getRequest('PersonData').subscribe((res) => {
      this.personData = FilterRes(res);
      this.personData.map(
        (item) =>
          (item.name =
            GetLanguage() === 'ar' ? item.firstNameAr : item.firstNameEN),
      );
      this.handlerAutocomplete('user');
      //console.log("this.pers",this.personData)
    });
  }

  getAllCashierUsers(controlName) {
    this.coreService.getRequest(this.allCashierApi).subscribe((res) => {
      this.cashierUsers = FilterRes(res);
      this.cashierUsers.map(
        (item) => (item.name = item.firstName + ' ' + item.familyName),
      );
      this.handlerAutocomplete(controlName);
    });
  }

  getFacultyMembers() {
    const panelId = secureStorage.getItem('resultsPanelId');
    this.coreService
      .getRequest(`FacultyMemberPanel?PanelId=${panelId}`)
      .subscribe((res) => {
        this.facultyMembers = FilterRes(res);
        this.facultyMembers.map(
          (item) =>
            (item.name =
              item.facultyMember?.user?.firstName?.trim() +
              ' ' +
              item.facultyMember?.user?.socondName?.trim() +
              ' ' +
              item.facultyMember?.user?.thirdName?.trim() +
              ' ' +
              item.facultyMember?.user?.familyName?.trim()),
        );
      });
  }
  apply() {
    if (this.form.status === 'VALID') {
      this.filter.emit(this.form.value);
    }
  }

  resetCertificate(type) {
    if (type === 'type') {
      this.form.controls['basic_data_rate'].reset();
      this.form.controls['basic_data_country'].reset();
    } else if (type === 'rate') {
      this.form.controls['basic_data_country'].reset();
    }
    this.form.controls['basic_data_univesity'].reset();
    this.form.controls['basic_data_colleg'].reset();
    this.checkForGet();
  }

  checkForGet() {
    const value = this.form?.value;
    // console.log('check for get', value.educationType, value.rate , value.country)
    if (
      value.basic_data_education_type &&
      value.basic_data_rate &&
      value.basic_data_country
    ) {
      this.getSchoolAndUniversities(
        value.basic_data_education_type?.isUnivarsty,
      );
    }
  }

  getSchoolAndUniversities(isUniversity) {
    // console.log('')1');
    const body = {
      CountryId: this.form.value.basic_data_country?.id,
      EduRatingId: this.form.value.basic_data_rate?.id,
      IsUni: isUniversity,
    };
    // console.log(body)
    this.coreService
      .getRequest(
        `SchoolAndUniversity/GetUniAndSchoolFilterd?CountryId=${body?.CountryId}&EduRatingId=${body?.EduRatingId}&IsUni=${body?.IsUni}`,
      )
      .subscribe((res) => {
        // console.log('')res filtered', res);
        const types = FilterRes(res);
        types.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        this.universities = types;
        this.handlerAutocomplete('basic_data_univesity');
      });
  }

  schoolAnUniChanged() {
    this.form.controls['basic_data_colleg'].reset();
    if (!this.form.value.basic_data_univesity?.isNoNeedSectors) {
      // this.sendSecAndCol(this.form.value.type?.id, {});
      // this.getSecAndCol();
      this.getSections();
    }
  }

  getSections() {
    this.coreService
      .getRequest(
        `SecAndCollage/GetSecCollegByUniId?UniId=${this.form.value.basic_data_univesity?.id}`,
      )
      .subscribe((res) => {
        res = FilterRes(res);
        res?.map(
          (item) =>
            (item.name = GetLanguage() === 'ar' ? item.nameAr : item.nameEn),
        );
        if (this.form.value.basic_data_education_type?.isUnivarsty) {
          this.colleges = res.filter((x) => x.isUnivarsty === true);
        } else {
          this.colleges = res.filter((x) => x.isUnivarsty === false);
        }
        this.handlerAutocomplete('basic_data_colleg');
      });
  }

  chosenYearHandler(normalizedYear: Moment, type) {
    this.form.controls[type].setValue(moment());
    const ctrlValue = this.form.value[type];
    ctrlValue.year(moment(normalizedYear).year());
    this.form.controls[type].setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>,
    type,
  ) {
    const ctrlValue = this.form.value[type] || moment();
    ctrlValue.month(moment(normalizedMonth).month());
    this.form.controls[type].setValue(
      new Date(moment(ctrlValue).startOf('month').format('YYYY-MM-DD')),
    );
    datepicker.close();
  }
  get isCivilId() {
    return CIVIL_ID_LIST.includes(this.listName);
  }
  get isAccountType() {
    return ACCOUNT_TYPE_LIST.includes(this.listName);
  }
  get isUsername() {
    return USERNAME_LIST.includes(this.listName);
  }
  get isEmail() {
    return EMAIL_LIST.includes(this.listName);
  }
  get isPhone() {
    return PHONE_LIST.includes(this.listName);
  }
  get isPassport() {
    return PASSPORT_LIST.includes(this.listName);
  }
  get isReviewDate() {
    return REVIEW_DATE_LIST.includes(this.listName);
  }
  get isFromDate() {
    return FORM_DATE_LIST.includes(this.listName);
  }
  get isToDate() {
    return TO_DATE_LIST.includes(this.listName);
  }
  get isFileNum() {
    return FILE_NUM_LIST.includes(this.listName);
  }
  get isReceiptNum() {
    return RECEIPT_NUM_LIST.includes(this.listName);
  }
  get isName() {
    return NAME_LIST.includes(this.listName);
  }
  get isService() {
    return SERVICE_LIST.includes(this.listName);
  }
  get isFromDateRequired() {
    return FROM_TO_DATE_REQUIRED_LIST.includes(this.listName);
  }
  get isToDateRequired() {
    return FROM_TO_DATE_REQUIRED_LIST.includes(this.listName);
  }
  get isMemberShipNum() {
    return MEMBERSHIP_NUM_LIST.includes(this.listName);
  }
  get isArabicName() {
    return ARABIC_ENGLISH_NAME_LIST.includes(this.listName);
  }
  get isEnglishName() {
    return ARABIC_ENGLISH_NAME_LIST.includes(this.listName);
  }
  get isPaymentMethod() {
    return PAYMENT_METHOD_LIST.includes(this.listName);
  }
  get isUser() {
    return USER_LIST.includes(this.listName);
  }
  get isActionCode() {
    return ACTION_CODE_LIST.includes(this.listName);
  }
  get isCompanyFileNumber() {
    return COMPANY_FILE_NAME_LIST.includes(this.listName);
  }
  get isDate() {
    return DATE_LIST.includes(this.listName);
  }
  get isKuwitPhoneNumber() {
    return KUWAIT_PHONE_FULL_NAME_LIST.includes(this.listName);
  }
  get isFirstName() {
    return KUWAIT_PHONE_FULL_NAME_LIST.includes(this.listName);
  }
  get isSecondName() {
    return KUWAIT_PHONE_FULL_NAME_LIST.includes(this.listName);
  }
  get isThirdName() {
    return KUWAIT_PHONE_FULL_NAME_LIST.includes(this.listName);
  }
  get isFamilyName() {
    return KUWAIT_PHONE_FULL_NAME_LIST.includes(this.listName);
  }
  get isClassificationType() {
    return CLASSIFICATION_TYPE_LIST.includes(this.listName);
  }
  get isEvalutionType() {
    return EVALUATION_TYPE_LIST.includes(this.listName);
  }
  get isEvaluationDate() {
    return EVALUATION_DATE_LIST.includes(this.listName);
  }
  get isProfession() {
    return PROFESSION_LIST.includes(this.listName);
  }
  get isIsMembership() {
    return IS_MEMBERSHIP_LIST.includes(this.listName);
  }
  get isCategoryName() {
    return CATEGORY_NAME_LIST.includes(this.listName);
  }
  get isNOC() {
    return NOC_LIST.includes(this.listName);
  }
  get isCashierUser() {
    return CASHIER_USER_LIST.includes(this.listName);
  }
  get isNationality() {
    return NATIONALITY_LIST.includes(this.listName);
  }
  get isJob() {
    return JOB_LIST.includes(this.listName);
  }
  get isEvalutionStatus() {
    return EVALUATION_STATUS_LIST.includes(this.listName);
  }
  get isResidencyType() {
    return RESIDENCY_TYPE_LIST.includes(this.listName);
  }
  get isStatusType() {
    return STATUS_TYPE_LIST.includes(this.listName);
  }
  get isCompanyName() {
    return COMPANY_NAME_LIST.includes(this.listName);
  }
  get isCompanyFileNum() {
    return COMPANY_FILE_NUM_LIST.includes(this.listName);
  }

  wheel(e) {
    if (e?.wheelDelta < 0) {
      this.companiesScroll.setValue(true);
    }
  }
  subscribeToGetScroll() {
    this.companiesScroll.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500))
      .subscribe((res) => {
        this.pageNumber = this.pageNumber + 1;
        this.getAllCompanies();
      });
  }
  subscribeToGetCompanies() {
    this.companiesFilter.valueChanges
      .pipe(untilDestroyed(this), debounceTime(1000))
      .subscribe((res) => {
        this.getAllCompanies();
      });
  }
  getRemoteCompanies(filter) {
    if (this.companies.filter((x) => x.name.includes(filter))?.length === 0) {
      this.search_word = filter;
      this.companiesFilter.setValue(this.search_word);
    }
  }
  getAllCompanies() {
    let url;
    if (this.search_word) {
      url = `Company?${this.search_word?.length ? '&filenum=' + this.search_word : ''}`;
    } else {
      url = `Company?${this.search_word?.length ? '&filenum=' + this.search_word : ''}${'&PageNumber=' + this.pageNumber + '&PageSize=20'}`;
    }
    this.coreService.getRequest(url).subscribe((res) => {
      // console.log('res jobs', res)
      this.companies = [...this.companies, ...FilterRes(res)];
      this.companies.map(
        (item) =>
          (item.name = GetLanguage() === 'ar' ? item.nAmeArabic : item.nameEng),
      );
      this.fillDropDownMultiSelect();
      //this.companiesLoading = false;
    });
  }
  fillDropDownMultiSelect() {
    this.dropdownSettings = Object.assign(
      {
        ...getDropdownSettings(this.translateService),
        idField: 'id',
        textField: 'name',
      },
      {
        singleSelection: true,
        allowRemoteDataSearch: true,
        closeDropDownOnSelection: true,
      },
    );
    this.dropdownList = this.companies;
  }
  selectMonth(event) {
    this.monthSelected.emit(event);
  }
}
